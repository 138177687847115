import React from "react";
import "../../App.scss";
import { CMdSPortals } from "../../SharedModules/AppConstants";

function ProfileCard(props) {
  const { userData, fromPortal, portalLogOut } = props;
  const makeTabActive = (wn, url) => {
    const tabToActivate = window.open(
      process.env.REACT_APP_PRODUCT_URL,
      CMdSPortals
    ); // env file product dev
    if (!tabToActivate.closed) {
      tabToActivate.focus();
    }
  };
  const logo = userData?.lastName?.charAt(0) + userData?.firstName?.charAt(0);
  const userName =
    userData?.firstName && userData?.lastName
      ? userData?.lastName + " " + userData?.firstName
      : "";
  const email = userData?.email;

  return (
    userName && (
      <div class="profile_card">
        <div class="card-body">
          <div className="row">
            <div className="col">
              <span className={"card-logo-border cardLogo"}>
                {logo ? logo : ""}
              </span>
            </div>
            <div className="card_detail">
              <h6
                title={userName ? userName : ""}
                class="card-subtitle mb-2 card_wordWrapper"
              >
                {userName ? userName : ""}
              </h6>
              <p title={email} className="card-text card_wordWrapper font12">
                {email}
              </p>
              {props.showLink && props.showLink.includes("Home") && (
                <div>
                  <a
                    class="card-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      makeTabActive(CMdSPortals, "");
                    }}
                  >
                    Home
                  </a>
                </div>
              )}
              {props.showLink && props.showLink.includes("MyAcc") && (
                <div>
                  <a
                    class="card-link"
                    style={{ cursor: "pointer" }}
                    onClick={
                      fromPortal
                        ? props.handleMyaccount
                        : props.navigateToMyAccount
                    }
                  >
                    My Account
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
          <div className={`card-footer logOut text-right bg-white`}>
            <a
              class="card-link text-dark text-decoration-none"
              style={{ cursor: "pointer" }}
              onClick={portalLogOut ? props.logOutFromPortal : props.logOut}
            >
              {(localStorage.getItem("logInType") !== 'NORMAL_LOGIN' && process.env.REACT_APP_LOGOUT) || 'Logout'}
            </a>
          </div>
      </div>
    )
  );
}
export default ProfileCard;
