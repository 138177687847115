// Global messages
export const URC_Parameter_Number_Req_Error = "Parameter Number is required.";
export const URC_Description_Req_Error = "Description is required.";
export const URC_Description_Length_Req_Error =
  "UR Parameter Description should not be more than 30 characters.";
export const URC_LOB_Req_Error = "Line of buisness is required.";
export const URC_ExeptionCode_Req_Error = "Exception Code is required.";
export const URC_History_Req_Error = "History is required.";
export const SELECT_DATA = "Select Record to be Saved";
export const SELECT_DATA_SUBMIT = "Select Record to be Submitted";
export const MEMBER_ID_TYPE = "Select Record to be Submitted";
export const URC_Include_Exclude_Req_Error =
  "Procedure/Revenue Range or Procedure/Revenue List Num required when Include or Exclude selected.";
export const BUSINESS_NAME_ERROR =
  "A minimum of 2 characters must be entered for a starts with search and a contains search.";
export const DATA_ELEMENT_NAME_ERROR = "Either code or text is required.";
export const URL_LIMIT_MET_CODE_REQ_ERR =
  "Limit Met Exception Code is required. ";
export const URL_LIMIT_OVER_CODE_REQ_ERR =
  "Limit Over Exception Code is required.";
export const URL_LIMIT_EXCP_CODE_NOT_FILE_ERR = "Exception Code not on file.";
export const URL_TIME_PERIOD_TYPE_REQ_ERR = "Time Period is required.";
export const URL_QUANTITY_ERROR = "Data entered is in the incorrect format.";
export const URL_TIME_PERIOD_QUANTITY_REQ_ERR =
  "Both Time Period Quantity and Limit Amount Quantity must be greater than zero.";
export const URL_LIMIT_AMT_TYPE_REQ_ERR = "Limit Amount Type is required.";
export const URL_LIMIT_AMT_QUANTITY_REQ_ERR =
  "Both Time Period Quantity and Limit Amount Quantity must be greater than zero.";
export const URC_REVENUE_FROM_CODE_ERROR =
  "Invalid Revenue Code: Revenue must be four characters, all numeric.";
export const URC_REVENUE_THRU_CODE_ERROR =
  "Invalid Revenue Code: Revenue must be four characters, all numeric.";
export const URC_REVENUE_PROCEDURE_LIST_ERROR =
  "Procedure/Revenue Range not allowed when Procedure/Revenue List Number selected.";
  export const MEMBER_ID_TYPE_REQ = "Member ID Type is required.";
export const RATE_REVENUE_CODE_REQ_ERR = "Revenue Code is required.";
export const RATE_ALPHA_INVALID_ERR = "Please enter valid code.";
export const RATE_REVENUE_CODE_FOURDIGIT_ERR =
  "Invalid Revenue Code: Revenue must be four characters, all numeric.";
export const REGION_CODE_REQ_ERR = "Region is required.";
export const REVENUE_TYPE_REQ_ERR = "Revenue Type is required.";
export const PRO_CODE_NOT_EXIST = "Provider ID not on the Provider file.";
export const PROVIDER_ID_TYPE_REQUIRED = "Provider ID Type Code is required.";
export const REVENUE_CODE_NOT_EXIST = "Revenue Code not on file.";
export const NO_RECORDS_FOUND = "No records found.";
export const NO_RECORDS_WITHSEARCH =
  "No records found for the search criteria entered.";
  export const NO_RECORDS_REVIEW =
  "No record is found. Please review your search criteria.";
export const NO_RECORDS_PEND_STATUS = "Pended Policy not found.";
export const ERROR_OCCURED_DURING_TRANSACTION =
  "There was an error processing the request. Please retry the transaction.";
export const DATE_RANGE_ERROR =
  "End date must be after the begin date of tax period.";
export const CLIA_DATE_OVERLAP_ERROR =
  "CLIA Number entered is already in the system for selected Procedure Code for the same dates. Duplicate CLIA Number information  is not allowed.";
export const INPUT_FORMAT_ERROR = "Please enter data in correct format.";
export const Begin_Date_Error = "Begin Date is required.";
export const End_Date_Error = "End Date is required.";
export const BEGIN_DATE_ERROR = "Begin Date is required.";
export const END_DATE_ERROR = "End Date is required.";
export const Invalid_Begin_Date_Error =
  "Begin Date that was entered is invalid.";
export const Invalid_End_Date_Error = "The End Date that was entered is invalid.";
export const The_Invalid_End_Date_Error =
  "The End Date that was entered is invalid.";
export const The_Invalid_Begin_Date_Error =
  "The Begin Date that was entered is invalid.";
export const Plan_Begin_Date_Requried_Error = "Plan Begin Date is required.";
export const Plan_End_Date_Requried_Error = "Plan End Date is required.";
export const Plan_End_Date_Error = "Plan end date is invalid.";
export const Fail_End_Date_Error =
  "Failed to save the record. Please connect Technical Support";
export const Plan_Begin_Date_Error = "Plan Begin date is invalid.";
export const NON_VOID_CI_DATES_GAPS =
  "Non-voided Codes & Indicators Dates Must Cover All Days in Header Dates with No Gaps or Overlaps.";
export const Gap_Btw_Detail_Rows_Dates =
  "There cannot be gaps between date spans across the detail rows.";
export const Short_Desc_Error = "Short Description is required.";
export const Long_Desc_Error = "Long Description is required.";
export const Header_Dates_ReqBfr_Error =
  "Header Dates are required before the detail lines can be save.";
export const Bgndt_GT_Enddt_Err =
  "The begin date must be less than or equal to the end date.";
export const Fall_In_Header_Dates_Err =
  "Begin/End Dates at the detail level must fall within the Begin and End Dates at Header Level.";
export const Fall_In_Header_Dates_Err_provider_ntwrk =
  "For each effective-dated row in a table, the begin date must be greater than or equal to the plan begin date and less than or equal to the plan end date.";
export const Fall_In_Header_End_Date_Err =
  "For each effective-dated row in a table, the end date must be less than or equal to the plan end date and greater than or equal to the plan begin date.";
export const Date_Range_Error =
  "For each effective-dated row in a table, the begin date must be less than or equal to the end date.";
export const Former_Code_Header_Dates_Err =
  "Former code's detail dates are not within the former code's header dates. ";

export const Starts_With_Contains_Error =
  "A minimum of 2 characters must be entered for a starts with search and a contains search.";
export const OneCI_Error =
  "At least one detail row is required in Codes and Indicators.";
export const Age_Year_Error =
  "The valid range for the year portion of the age is between 0 and 999.";
export const Age_Month_Error =
  "The valid range for the month portion of the age is between 0 and 11.";
export const Overlaps_Btw_Detail_Rows_Dates =
  "There cannot be overlaps between date spans across the detail rows.";
  
export const Non_DRG_Overlap ="Non-DRG rate with the same or overlapping begin and end dates for the same charge mode and level of care already exists.  Duplicates are not allowed.";
export const Overlaps_Btw_CLA_Code_Dates =
  "The dates cannot overlap for the same CLIA Code for a Procedure Code.";

export const Multiple_ASC_Group_For_Same_Date_Error =
  "Multiple ASC groups are not allowed for a procedure code for the same dates.";
export const Multiple_Medicare =
  "Multiple Medicare Coverage Segments are not allowed for a procedure code with same dates.";
export const Min_Age_GT_Max_Age_Error =
  "Minimum age must be less than or equal to maximum age.";
export const BDT_LT_CDT_DELETE_ERROR =
  "In order to delete this item, the begin date must be greater than or equal to the current date.";
export const DELETION_NOT_ALLOWED =
  "This data exists in the former procedure code table and the data element cannot be deleted.";
export const DATA_INCORRECT_FORMAT = "Data entered is in the incorrect format.";
export const DELETE_FAIL_MSG =
  "This item cannot be deleted because it is tied to another data element.";
export const PROV_TYPE_DATE_OVERLAP_ERR =
  "Begin and end dates within the same Field Set cannot be the same or overlap between rows";

// Global messages

// Provider Inquiry messages
export const PROVIDER_ID_REQUIRED = "Provider ID is required.";
export const ID_TYPE_REQUIRED = "ID Type is required.";
export const FIRST_SEARCH_REQUIRED = "First Search by is required.";
export const PROVIDER_NAME_REQUIRED = "Provider Name is required.";
export const PROVIDER_TYPE_REQUIRED = "Provider Type is required.";
export const PROVIDER_SPECIALITY_REQUIRED = "Provider Specialty is required.";
export const NETWORK_REQUIRED = "Network is required.";
export const TAXONOMY_REQUIRED = "Taxonomy is required.";
export const CLIA_REQUIRED = "CLIA is required.";
export const LICENSE_CERT_NUMBER_REQUIRED = "License/Cert Number is required.";
export const CITY_REQUIRED = "City is required.";
export const COUNTRY_REQUIRED = "County is required.";
export const DISTRIC_OFFICE_REQUIRED = "District Office is required.";
export const ZIP_CODE_REQUIRED = "Zip code is required.";
export const SPOKEN_LANGUAGE_REQUIRED = "Spoken language is required.";
// Provider Inquiry messages

//Procedure Code messages start
export const Proc_Code_Req_Error = "Procedure Code is required.";
export const Proc_Code_Invalid_Error =
  "Procedure Code was not entered in the correct format. The valid formats are: A9999, 99999, 9999A or 999.";
export const MODIFIERS_ERROR =
  "Procedure Code is required when modifier(s) is/are entered as one of the search criteria.";
export const PROCEDURE_PERCENTAGE_ERROR = "Percent Covered is required.";
export const PRC_DL_MA_REQ_ERROR = "Medicare Allowable is required";
export const ASC_GROUP_REQUIRED = "ASC Group is required.";
export const LAB_CODE_REQUIRED = "Lab Code is required.";
export const AT_LEAST_ONE_LAB_CODE =
  "At least one Lab Code row is required for a Lab Classification Date Span.";
export const DUBLICATE_LAB_CODE =
  "CLIA Lab Codes entered are already in the system for selected Procedure Code for same Begin and End dates. Duplicate CLIA Lab Code information is not allowed.";
export const DUPLICATE_CLIA_CODE =
  "CLIA Number entered is already in the system for selected Procedure Code for the same dates.  Duplicate CLIA Number information is not allowed.";
export const FACTCODREQERR = "Factor Code is required.";
export const PLOBREQERR = "Line of Business is required.";
export const SCREENTYPE = "Screening Type is required.";
export const YEARS = "Years is required.";
export const MONTHS = "Months is required.";
export const MAXALWAMTREQERR = "Maximum Allow Amount is required.";
export const RATESRCREQERR = "Rate Source is required.";
export const PRSNREQERR = "Reason is required.";
export const MAXUNTSREQERR = "Maximum Units is required.";
export const PRMULTPLRREQERR = "Multiplier is required.";
export const PCPTREQERR = "Provider Type is required.";
export const DATE_RANGE_IGNORE =
  "New Date Range cannot be added with ignore option.";
export const SELECT_ATLEAST_ONE =
  "Please Select at least one value from available list.";
export const Former_PC_NOT_FOUND =
  "Former Codes and/or Code modifier combination not found.";
export const Former_PC_EXIST =
  "The Former Code and/or Code modifier combination entered is already in the system. Duplicate codes are not allowed.";
export const ABORTION_GENDER_ERROR = "For Abortion, Gender must be Female.";
export const HYSTERECTOMY_GENDER_ERROR =
  "For Hysterectomy Gender must be Female.";
export const FACTOR_CODE_OVERLAP =
  "Overlap of Factor Codes cannot exist within same LOB, Dates and Factor Code type.";
export const PROF_LOB_EXIST =
  "Procedure code's professional component cannot overlap within the same LOB and begin and end date";
export const TECH_LOB_EXIST =
  "Procedure code's technical component cannot overlap within the same LOB and begin and end date.";
export const DATA_INCORRECT_FORMAT_G8 =
  "Procedure Pricing Factor of Percentage requires an amount greater than 0.00 and less than or equal to 1.00";
export const PROC_DATALIST_DATE_OVERLAP_ERR =
  "Begin and end dates within the same Field Set cannot be the same or overlap between rows";
//Procedure Code messages end

//Diagnosis Code messages start
export const Diag_Code_Req_Error = "Diagnosis Code is required.";
export const Icd_Ver_Req_Error = "ICD Version is required.";
//Diagnosis Code messages end
//CLAIM ENTRY -> VISIT Start
export const D_And_Q_Code_Req_Error =
  "Diagnosis codes and Diagnosis Qualifier codes are required.";
export const D_Req_When_Q_Enter_Error =
  "Diagnosis codes are required when Diagnosis Qualifier Codes are entered.";
export const Q_Req_When_D_Enter_Error =
  "Diagnosis Qualifier codes are required when Diagnosis Codes are entered.";
//CLAIM ENTRY -> VISIT End
// CLAIM INQUIRY MESSAGES START
export const claim_inquiry_error =
  "TCN OR Batch Number, Julian Date and Media Source is required.";
export const TCN_Error = "TCN is Required.";
export const TCN_NUM_Error = "TCN is invalid. Format must be 17 digit.";
export const CORRECTION_TCN_NUM_Error =
  "TCN is invalid. Format must be 17 digit.";
export const BEGINDOSERR = "Begin Date of Service is required.";
export const ENDDOSERR = "End Date of Service is required.";
export const BEGINADJERR = "Adjudication Begin Date is required.";
export const ENDADJERR = "Adjudication End Date is required.";
export const TCN_Batch_NUM_Error =
  "Please enter a TCN *or* Batch Data information, but not both.";
export const Batch_No_Error = "Batch Number is Required.";
export const MEMBER_ID_INV_Error =
  "Member Id is Invalid. Format must be 10 digits.";
export const Batch_No_INV_Error =
  "Batch Number is invalid. Format must be 4 digit numeric.";
export const mass_batch_no_Error =
  "Batch Number must be between 9000 and 9999 inclusive.";
export const JULIAN_DATE_INV_Error = "Julian Date is invalid.";
export const Julian_Date_Error = "Julian Date is Required.";
export const Media_Source_Error = "Media Source is Required.";
export const PROVIDER_ROLE_Error = "Provider Role is Required.";
export const PROVIDER_ID_TYPE_Error = "Provider ID Type Code is required.";
export const PROVIDER_ID_Error = "Provider ID is Required.";
export const External_TCN_Error = "External TCN is required.";
export const MEMBER_ID_Error = "Member ID is required.";
export const PROVIDER_DATA_Error =
  "Provider Data and/or Member Data is required.";
export const CORRECTION_ADV_SRCH_ERR  = 'Provider Data, Member Data and/or Claim Type is required.';  
export const DATE_ERROR_INV =
  "Date of Service Begin Date cannot be greater than Current Date.";
export const DATE_LESSTHAN_ERROR_INV =
  "Date of Service Begin Date must be less than or equal to Date of Service End Date.";
export const Value_Field_Error1 =
  "Value is required for Category of Eligibility in List.";
export const Value_Field_Error =
  "Value is required for Nursing Facility Span Type in List";
export const FUNCTIONAL_AREA_ERROR =
  " Functional Area is required for Category of Eligibility in List.";
export const FUNCTIONAL_AREA_ERROR1 =
  " Functional Area is required for Nursing Facility Span Type in List";
export const Network_ID_Error = "Network ID is required.";
export const Network_Status_Error = "Network Status is required.";
export const Rate_Type_Code_Error = "Rate Type Code is required.";
export const Rank_Error = "Rank is required.";
export const Rate_Error = "Rate is required.";
export const Invalid_Rate_Error =
  "Incorrect rate (should be a dollar amount with two decimals).";
export const Invalid_Rank_Error = "Rank that was entered is invalid.";
export const Map_Id_Error = "Map ID is required.";
// CLAIM INQUIRY MESSAGES END
export const Benifit_Hie_Error =
  "For a given benefit Plan hierarchy, the begin date must be less than or equal to the end date.";
export const BENEFIT_PLAN_ERROR = "Failed To Update the record";
export const BENEFIT_PLAN_SUCCESS_ERROR =
  "System successfully Updated the Information.";
export const BENEFIT_PLAN_DELETE_ERROR = "Ranking Details Deleted Successfully";
export const BENEFIT_PLAN_ENDDATE_ERROR =
  "For a given benefit plan hierarchy, the begin date must be less than or equal to the end date.";
export const BENEFIT_PLAN_RANK_ERROR =
  "The rank field should be unique for each active (non-voided) row in an effective dated benefit plan hierarchy.";
export const BENEFIT_PLAN_BPID_ERROR =
  "BP ID within a hierarchy should be unique.";
export const BENEFIT_PLAN_SAVE_ERROR = "Error saving in BP Hierarchy.";
export const BENEFIT_PLAN_UPDATE_ERROR = "Error Saving BP Hierarchy details.";
export const BENEFIT_PLAN_BEGIN_DATE_ERROR = "Incorrect begin date format.";
export const BENEFIT_PLAN_HIERARCHY_BEGIN_DATE_ERROR = "Incorrect begin date.";
export const BENEFIT_PLAN_HIERARCHY_END_DATE_ERROR =
  "Incorrect end date format.";
export const BENEFIT_PLAN_END_DATE_ERROR = "Incorrect end date format.";
export const BENEFIT_PLAN_RANKING_ZERO_ERROR = "Rank Should be Numeric.";
export const BENEFIT_PLAN_RANKING_ZERO =
  "The rank field must be a numeric value greater than zero.";
export const BENEFIT_PLAN_DEFAULT_WARNING =
  "A change to the benefit plan hierarchy ranking has occurred that potentially impacts claims that have already been processed and a mass claims adjustment may be required.";
export const BENEFIT_PLAN_DATE_OVERLAP_ERROR =
  "Begin and End dates should not overlap for Benefit Plan Hierarchy when there is more than one active hierarchy available in the system. However the user can void a hierarchy and add another hierarchy with the same begin and end date";
export const BENEFIT_PLAN_DATE_OVERLAP_ERROR_PAST =
  "For a benefit plan hierarchy, the user can only change the end date when the current end date is greater than System Date / System Cycle date (i.e. future dated).";
//Add Benifit Plan
export const LOB_ID_Error = "LOB is required.";
export const Benefit_Plan_ID_Error = "Benefit Plan ID is required.";
export const Benefit_Plan_Desc_Error = "Benefit Plan Description is required.";
export const Benefit_Plan_Type_Error = "Benefit Plan Type is required.";
export const Benefit_Plan_Option_Error = "Benefit Plan Option is required.";
export const Benefit_Plan_Status_Error = "Benefit Plan Status is required.";
export const Retro_Procedure_Error = "Retro Procedure Code is Required";
export const Current_Procedure_Error = "Current Procedure Code is Required";
export const NWTACC_REQ_Error =
  "At least one detail row is required in Network Association.";
export const RANK_TABLE_OVERLAP =
  "There cannot be duplicate ranked rows on any benefit plan component tables.";
export const NETWORK_TABLE_OVERLAP =
  "Network ID and Begin Date MUST NOT BE IN LIST of existing Network ID and Begin Date.";
export const CAPITATION_OVERLAP =
  "Same Combination of Map ID,Begin Date and Network Status MUST NOT BE IN LIST of existing Combination of Map ID,Begin Date and Network Status.";
export const NETWORK_MAP_OVERLAP =
  "Same Combination of Network Status,Map ID and Begin Date MUST NOT BE IN LIST of existing combination of Network Status,Map ID and Begin Date.";
export const PLAN_LIMIT_NETWORK_MAP_OVERLAP =
  "Same Combination of Network Status,Map ID,Begin Date and LimitTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and LimitTypeCode.";
export const COPAY_OVERLAP_ERROR =
  "Same Combination of Network Status, Service Type Code, Provider Type Code, Minimum Age, Begin Date and Co-pay Type Code MUST NOT BE IN LIST of existing Combination of Network Status, Service Type Code, Provider Type Code, Minimum Age, Begin Date and Co-pay Type Code.";
export const COPAY_TYPE_CODE_OVERLAP_ERROR =
  "Same Combination of Network Status,Map ID,Begin Date and Co-PayTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and Co-PayTypeCode.";
export const COINSURANCE_OVERLAP_ERROR =
  "Same Combination of Network Status,Map ID,Begin Date and Co-InsuranceTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and Co-InsuranceTypeCode.";
export const DEDUCATIBLE_OVERLAP_ERROR =
  "Same Combination of Network Status,Map ID,Begin Date and DeductibleTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and DeductibleTypeCode.";
export const Sa_Rule_Error = "SA Rule Code is required.";
export const TYPE_CODE = "Type code is required";
export const INDIVIDUAL_LIMIT = "Individual Limit is required";
export const LIMIT_MET_EXC_CODE = "Limit Met Exc code is required";
export const LIMIT_MET_OVER_EXC_CODE = "Limit Met Over Exc Code is required";
export const PERCENTAGE_APPLIED_TO_OUT_OF_POCKET_EMPTY =
  "Percentage applied to out of pocket max is requried";
export const PERCENTAGE_APPLIED_TO_OUT_OF_POCKET =
  "Incorrect percentage applied to OOP max (e.g. one hundred dollars = 100.00).";
export const PERCENTAGE_ERROR =
  "Incorrect percentage (e.g. one hundred percent = 100.00).";
export const COPAY_AMT_ERROR =
  "Incorrect copay amount format (e.g. one hundred dollars = 100.00)";
export const MIN_AGE = "Min age is required";
export const SERVICE_TYPE_CODE = "Service type code is required";
export const ShowLimitMetExcErr = "Limit Met Exc code is required";
export const CoInsTypeCode = "Co-Insurance Type Code is required";
export const AmountError = "Either Amount or Percent is required";
export const PercentAppliedError =
  "Percent Applied to Out of Pocket Max is Required";
export const TypeCodeInsError = "Limit Type Code is required";
export const Invalid_Ind_Amount =
  "Individual Limit that was entered is invalid";
export const Invalid_Ind_Pls_Amount =
  "Individual + Limit that was entered is invalid";
export const Invalid_Fam_Amount = "Family Limit that was entered is invalid";
export const INVALID_IND_LIMIT =
  "Incorrect individual limit (e.g. one hundred dollars = 100.00)";
export const INVALID_IND_PLUS_LIMIT =
  "Incorrect individual+1 limit (e.g. one hundred dollars=100.00)";
export const INVALID_IND_FAM_LIMIT =
  "Incorrect family limit format (e.g. one hundred dollars = 100.00)";
export const IND_LIMIT_MAX =
  "Individual Limit cannot be greater than 99999999999.99";
export const IND_PLUS_LIMIT_MAX =
  "Individual +1 Limit cannot be greater than 99999999999.99";
export const IND_FAM_LIMIT_MAX =
  "Family Limit cannot be greater than 99999999999.99";
export const INVALID_LMT_OVR_EXC_CODE = "Limit Over Exception Code is invalid.";
export const INVALID_EXC_CODE = "Exception Code is invalid.";
export const DEDUCTABLE_INVALID_EXC_CODE =
  "Deductible Met Exception Code is invalid.";
export const BENIFIT_INVALID_LMT_OVR_EXC_CODE =
  "Limit Over Exception Code must not contain special characters.";
export const INVALID_SPL_EXC_CODE =
  "Exception Code must not contain special characters.";
export const INVALID_LMT_MET_EXC_CODE = "Limit Met Exception Code is invalid.";
export const INVALID_LMT_MET_EXC_CODE_SPE =
  "Limit Met Exception Code must not contain special characters.";
export const INVALID_EXCEPTION_CODE =
  "The Benefit Plan Exception Code does NOT match with a LOB Exception Code.";
export const EXC_CODE_NOT_MATCH_LOB =
  "The Benefit Plan Exception Code does NOT match with a LOB Exception Code.";
export const BENEFIT_PLAN_ID_SPCL_CHAR_ERR =
  "Benefit Plan ID must not contain special characters";
export const BENEFIT_BUSS_UNIT_SPCL_CHAR_ERR =
  "Business Unit must not contain special characters";
export const BENEFIT_PLAN_DESC_SPCL_CHAR_ERR =
  "Benefit Plan Description must not contain special characters";
export const INVALID_AGE =
  "Minimum Age should be a positive whole number between 0-999. Decimal values are not allowed.";
//success message//
export const SUCCESSFULLY_SAVED_INFORMATION =
  "System successfully saved the Information.";
export const BENIFIT_PLAN_DELETE_SUCCESS =
  "System successfully deleted the Information.";
export const INVALID_AGE_NAN = "Min age must be a positive whole number.";

export const PI_REQ_Error = "Policyholder ID is required.";

//Service Authorization Error
export const SA_ID_Error = "The SA ID is required.";
export const SA_PROVIDER_ID_Error = "Provider ID is Required.";
export const SA_INVALID =
  "SA Number is invalid. Format must be a 5 digit numeric.";
export const SA_MEMBER_ID_Error = "Member ID is required.";
export const SA_AUTH_TYPE = "Auth Type is required.";

//SA ADD STARTS
export const SA_MEDIA_TYPE_ERROR = "SA Media Type is required.";
export const SA_RECEIVED_DATE_ERROR = "Received Date is required.";
export const SA_AUTH_TYPE_ERROR = "Auth Type is required.";
export const EXTERNAL_SA_ID_ERROR = "External SA ID is a required field.";
export const HEADER_STATUS_REASON_ERROR = "When changing the Header Status on a Service Authorization to a value other than Approved, the Reason for Status Change  must be populated by the user."
export const SA_REQUESTED_BEGIN_DATE_ERROR =
  "Requested Begin Date is required.";
export const SA_REQUESTED_END_DATE_ERROR = "Requested End Date is required.";
export const SA_INPUT_SOURCE_ERROR = "SA Input Source is required.";
export const MEMBER_ID_ERROR = "Member ID is required.";
export const TYPE_OF_ATTACHMENT_REQ = "Type Of Attachment is required.";
export const DELIVERY_METHOD_REQ = "Delivery Method is required.";
export const MEMBER_ID_NOT_FOUND_Error ="Member ID not found for the Member ID Type selected on the database.";
export const SA_HEADER_STATUS = "Header Status is required.";
export const SA_PROVIDER_ERROR = "Provider is required.";
export const SA_EXT_ERROR = 'Contact Phone Ext cannot be entered without a Phone Number';
export const SA_MAIL_FAX_ERROR = 'When Provider Phone Ext is present, include only the Fax or Email but not both';
export const SERVICE_CODE_ERR = 'Service Code is a required field.'
export const SERVICE_CODE_FROM_ERR = 'Service Code From is a required field.'
export const SERVICE_CODE_TO_ERR = 'Service Code To is a required field.'
export const LIST_ERR = 'List is a required field.'
//SA SADD ENDS
export const SA_CODE_REQUIRED =
  "If either the Service Type Code or the Service Code are entered, then both are required.";
export const SA_BEGIN_DATE = "Begin Date is required.";
export const SA_END_DATE = "End Date is required.";
export const SA_SERVICE_AUTH_TYPE_Error = "Auth Type is required.";
export const SA_USER_ID_ERROR = "User ID is required.";
export const SA_USER_TYPE_ERROR = "User Type is required.";
export const SA_DATE_TYPE = "Date Type is required.";
export const SA_LOCATION_ERROR = "Location is required.";
export const SA_PROVIDER_TYPE = "Provider Type is required.";
export const SA_NO_RECORD_FOUND =
  "No records were found for the search criteria that was entered.";
export const SA_GREATER_DATE = "End Date should be greater than Begin Date.";
export const SA_PROIDER_ERROR =
  "Provider Type, Provider Id Type Code and Provider Id are all required if any are entered.";
export const SA_Invalid_Begin_Date_Error =
  "Begin Date is invalid.It should be in the format MM/DD/CCYY.";
export const SA_Invalid_End_Date_Error =
  "End Date is invalid.It should be in the format MM/DD/CCYY.";

export const Benefit_Plan_Amount_Error = "Benefit plan amount is required.";

//Batch Control Error
export const BATCH_DATE_ERROR = "Batch Date is a required field.";
export const BATCH_NUMBER_ERROR =
  "Batch Number is invalid. Format must be 4 digit numeric.";
export const BATCH_NUMBER_REQ = "Batch Number is a required field.";
export const TCN_NUMBER_ERROR = "TCN is invalid.Format must be 17 digit.";
export const BATCH_DATE_FORMAT_ERROR = "Batch Date is invalid.";

export const BATCH_DATE_REQ = "Batch Date is required.";
export const DATCH_DATE_INVALID = "Batch Date is invalid.";
export const JULIAN_DATE_INVALID =
  "The Julian Date is invalid. The Julian Date cannot be greater than todays date (YYDDD).";
export const BATCH_NUM_BWT_8_9 =
  "The Batch Number is invalid. The system reserved number sequence for a new batch is 8000 through 9999.";
  export const BATCH_NUM_BWT_9_0 =
  "The Batch Number is invalid. The system reserved number sequence for a new batch is 9000 through 9999.";

  export const BATCH_NUM_BET_0_7 =
  "The Batch Number is invalid. The system reserved number sequence for a new batch is 0001 through 7999.";
export const MEDIA_SRC_PAPER =
  'The Media Source Code must be "8-Paper" for a new batch.';
export const TOTAL_DOC_REQ = "Total Documents is required.";
export const BEGIN_DOC_REQ = "Begin Document is required.";
export const TOTAL_DOC_INVALID = "Total Documents is invalid.";
export const BEGIN_DOC_INVALID = "Begin Document is invalid.";
export const BEGIN_DOC_GRT_ZERO =
  "The Begin Document Number must be greater than zero.";
export const END_DOC_GRT_ZERO =
  "The End Document Number must be greater than zero.";
export const TOTAL_DOC_GRT_ZERO =
  "The Total Documents Number must be greater than zero.";
export const BEGIN_DOC_LESS_MAX =
  "The Begin Document Number must be less than 999,999.";
export const END_DOC_REQ = "End Document is required.";
export const END_DOC_INVALID = "End Document is invalid.";
export const TOTAL_DOC_GRT =
  "Total Documents should equal the End Document number minus the Begin Document number.";
export const TOTAL_DOC_LES =
  "The number of claims entered exceeds Total Documents.";
export const DOC_TYPE_REQ = "Document Type is required.";
export const BATCH_TYPE_REQ = "Batch Type is required.";
export const BATCH_STATUS_REQ = "Batch Status is required.";
export const PAYMENT_TYPE_REQ = "Payment Type is required.";
export const BATCH_TYPE_RELEVENT_ERR =
  "The Batch Type must be Replac Req or Credit for Adjustment batches.";
export const DOC_TYPE_RELEVENT_ERR =
  "The Document Type must be Adjustment for Replac Req or Credit batches.";
export const BATCH_CONTROL_DUPLICATE =
  "The Batch Date, Media Source and/or Batch Number combination must not exist to add a new batch control record.";
export const BATCH_CONTROL_DELETE_EXCEPTION =
  "This batch control record has claims associated and cannot be deleted.";
export const DOC_TYPE_ENC_MAT =
  "Encounter Document Type must be combined with History Only Payment Type.";
export const MAX_OOP_REQ = "Percent Applied to OOP Max is Required";
export const MAX_OPP_INVALID = "Percent Applied to OOP Max Invalid";
export const DEDUCT_TYPE_CODE_ERR = "Deductible Type Code is Required";
export const BATCH_DATE_REQUIRED_ERROR = "Batch Date is required.";
export const BATCH_NUMBER_REQUIRED_ERROR = "Batch Number is required.";
//Claims Entry Messages Start
export const BATCH_DATE_INVALID = "Batch Date is invalid.";

export const MASS_BATCH_DATE_INVALID =
  "Please enter the data in correct format.Example MM/DD/CCYY;MM-DD-CCYY;MMDDCCYY";
export const BATCH_DATE_REQUIRED = "Batch Date is required.";
export const BATCH_DATE_GT_CDT = "Batch Date must be less than Today's date.";
export const BATCH_DATE_GT_CURDT = "Batch Date must be less than or equal to Current Date.";
export const BATCH_DATE_OLD = "Batch Date can only go back 10 days in the past.";
export const PROV_ROLE_INVALID = "Prov Role is Invalid.";
export const PROV_ID_INVALID = "Prov ID Type is Invalid.";
export const OVR_EXC_REQ = "Override Exception is required.";
export const OVR_PAY_TYPE_REQ = "Pay is required.";
export const CE_DOC_NUM_INVALID = "Document Number is invalid.";
export const CC_OVR_LOC_REQ = "Override Loc is required.";
export const CC_OVR_USR_REQ = "User ID is required.";
//Claims Entry Messages End
// CLAIM CORRECTION MESSAGES START
export const LOCATION_CODE_ERROR = "Location Code is required.";
// CLAIM CORRECTION MESSAGES END

export const ATTACHMENT_REQ = "Attachments is required.";
export const REMARK_REQ = "Remark is required.";
export const ADJ_REASON_REQ = "Adj Reason is required.";
// Claims Advanced Tab Messages Start
export const MAX_ALLOW_AMT_ERR =
  "Amount cannot be greater than 99,999,999,999.99.";
export const Multiplier_err_frmt = "Data entered is in the incorrect format.";
export const Amt_Float_Error = "Amount entered must be a float.";
export const Amt_Incorrect_Format_Error =
  "Amount entered is in the incorrect format.";
export const Multp_Incorrect_Format_Error =
  "Data entered is in the incorrect format.";
export const Mult_Invalid_Entry =
  "Received non-numeric data in a numeric field.";
// Claims Advanced Tab Messages End

// CLAIM CORRECTION OTHER PAYER START
export const LINE_ITEM_ERROR = "LI # is required.";
export const BILLED_ERROR = "Billed Amt is required.";
export const ALLOWED_ERROR = "Allowed Amt is required.";
export const DEDUCATIBLE_ERROR = "Deductible Amt is required.";
export const COINSURANCE_ERROR = "Coinsurance Amt is required.";
export const PAID_ERROR = "Paid Amt is required.";
export const CO_PAY_ERROR = "CoPay Amt is required.";
// CLAIM CORRECTION OTHER PAYER END

export const LINE_ITEM_SVC_DATE_INVALID = "Line Item Service Date is invalid.";
export const LINE_ITEM_SVC_UNITS_INVALID = "Line Item Service Unit is invalid.";
export const LINE_ITEM_TOTAL_CHARGE_INVALID =
  "Line Item Total Charge is invalid.";
export const LINE_ITEM_BASE_RATE_INVALID = "Line Item Base Rate is invalid.";
export const LI_IS_REQ = "LI# is required";
export const LINE_REQ = "Line # is required";
export const LI_IS_INV = "LI# is invalid";
export const LINE_INV = "Line# is invalid";

//Claim Inquiry
//Claim Inquiry
export const DOS_Begin_Date_Error = "DOS Begin is required.";
export const DOS_End_Date_Error = "DOS End is required.";
export const DOS_Invalid_Begin_Date_Error = "DOS Begin is invalid.";
export const DOS_Invalid_End_Date_Error = "DOS End is invalid.";
export const PAID_Invalid_End_Date_Error = "Paid Date End is invalid.";
export const PAID_Invalid_Begin_Date_Error = "Paid Date Begin is invalid.";
export const ADJ_Invalid_Begin_Date_Error =
  "Adjudication Date Begin is invalid.";
export const ADJ_Invalid_End_Date_Error = "Adjudication Date End is invalid.";
export const CLAIM_ENTRY_VALIDATE = "Claim Validated Successfully.";
export const CLAIM_ENTRY_NO_DATA =
  "Data must be entered on claim form to successfully save. Please enter claim data.";
  export const SERVICE_DATE_FROM = "Date of Service From is required. ";
  export const SERVICE_DATE_TO = "Date of Service To is required. ";
//MEMBER SEARCH
export const SSN_Error = "SSN is required.";
export const SSN_INV_Error =
  "Please enter SSN in the correct format. Example 123-45-6789 Example 123456789";
export const SSN_ERROR_INVALID =
  "Please enter SSN in the correct format. Example 123-45-6789 Example 123456789";
export const BILLED_DATE_ERR = "Payment & Provider Sign / Date is Invalid.";
export const MEMBER_DATE_ERR = "Member Date of Birth is invalid.";
export const LAST_NAME_Error = "Last Name is required.";
export const LAST_NAME_ENV_Error = "Last name must be 2 or more characters.";
export const FIRST_NAME_ENV_Error = "First name must be 2 or more characters.";
export const FIRST_NAME_Invalid_char_Error = "First name must be 2 or more characters.";
export const ID_NUMBER_Error = "ID Number is required.";
export const MEMBER_MI_ERROR = "Member middle initial invalid.";
export const MEMBER_ZIP_ERROR = "Zip Code should contain only numbers.";
export const ZIPCODE_ERROR = "Invalid Zip Code.";
export const ID_TYPE_Error = "ID Type is required.";
export const VISIT_ILLNESS_ERROR = "Accident/Illness Date is Invalid.";
export const PAYMENT_DOB_ERR = "Other Insured Date of Birth is Invalid.";
export const SUBMITTED_DUPL_ROWS = "Duplicate Rows cannot be added.";
// member constants
export const MEMBER_DATE_REQ = "Member Date of Birth is Required.";
export const Invalid_From_Date_Error =
  "From Date is invalid. Date should be in mm/dd/yyyy format.";
export const Invalid_To_Date_Error =
  "To Date is invalid. Date should be in mm/dd/yyyy format.";
export const Invalid_DOB_Error =
  "Date of Birth is invalid. Date should be in mm/dd/yyyy format.";
export const INVALID_DATE_OF_BIRTH_ERROR =
  "Date of Birth is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY.";  

export const fromdt_GT_Todt_Err =
  "From Date must be less than or equal to the To Date";
export const Reqd_From_Date_Error = "From date is required";
export const DUPLICATE_MODIFIER = "Duplicate Modifiers are not allowed.";
export const LASTNAME_INVALID_Error =
  "Warning-Member last name contains invalid characters. System will continue processing the name as entered.";
export const FIRSTNAME_INVALID_Error =
  "Warning-Member first name contains invalid characters. System will continue processing the name as entered.";
export const LASTNAME_INVALID_Err = "Member last name invalid.";
export const CASE_LAST_NAME = "Case Head Last Name is required.";
export const CASE_NUMBER = "Case number is missing";
export const CASE_DATE_INVALID =
  "As of Date is invalid. Date should be in mm/dd/yyyy format.";
export const CASE_NO_RECORDS =
  "Case Head Name not found in the system. Please re-enter and try again.";
export const CASE_NUMBER_NO_RECORDS =
  "Case number not found in the system. Please re-enter and try again.";
// export const LASTNAME_INVALID_Error = "Warning-Member last name contains invalid characters. System will continue processing the name as entered.";
// export const FIRSTNAME_INVALID_Error = "Warning-Member first name contains invalid characters. System will continue processing the name as entered.";
export const PAYMENT_TOTAL_CHRG_ERR =
  "Payment & Provider Total Charge is Invalid.";
export const PAYMENT_NET_AMT_ERR = "Payment & Provider Net Amount is Invalid.";

export const CASE_NUMBER_ERROR = "Case Number should be AlphaNumeric.";
export const MEMBER_ID_MID_ERROR =
  "Member's MID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const MEMBER_ID_RID_ERROR =
  "Member's RID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const LAST_NAME_ORG_NAME_ERR =
  "Last Name or Organization Name is required.";
export const MEMBER_ID_NO_RECORD =
  "The combination of Member ID and Member ID Type cannot be found.";
export const SUCCESS_SAVE_REDIRECT_ENTRY =
  "System successfully saved the Information. Total documents have been entered for the given Batch Control record.";

// CLAIM EXCEPTION STARTS
export const DOC_TYPE_REQ_ERR = "Document Type is required";
export const CLAIM_TYPE_REQ_ERR = "Claim Type is required";
export const MEDIA_TYPE_REQ_ERR = "Media Type is required";
export const LOCATION_REQ_ERR = "Location is required";
export const CLAIM_EXP_RES_MT = "Please select All or Specific";
export const CLAIM_EXP_RES_MT_TO = "Please select a Media Type to copy to";
export const CLAIM_EXP_RES_MT_FROM = "Please select a Media Type to copy from";
export const CLAIM_LOB_FILTER = "Please select an LOB and then click filter.";
export const CLAIM_REQ_TABS =
  "Main and Disposition tab information is required to save.";
export const EXCEP_NUM = "Exception Code should be Numeric.";
export const TRACK_NUM_INVALID = "Tracking Number should be numeric.";
// CLAIM EXCEPTION ENDS

//MEDICAL CRITERIA STARTS
export const LIMIT_PARAMETER_NUMBER_ERR = "Parameter Number is required.";
export const LIMIT_LOB_ERR = "Line of Business is required.";
export const LIMIT_RANK_ERR = "Rank is required.";
export const MEDICAL_CRITERIA_CODE_ERR = "Code is required.";
export const NO_DATA_FOUND = "No data found";
export const MEDICAL_CRITERIA_ERR =
  "Atleast one Limit Parameter or Contraindicated Parameter is required.";
export const MEDICAL_PFORMAT_ERR =
  "Procedure Code was not entered in the correct format. The valid formats are: A9999, 99999, 9999A or 999";
export const MEDICAL_RFORMAT_ERR =
  "Invalid Revenue Code: Revenue must be four characters, all numeric";
export const MEDICALP_IE_COS_ERR = "Category of Service selected is required.";
export const MEDICALP_IE_POS_ERR = "Procedure Modifier selected is required.";
export const MEDICALP_IE_PM_ERR = "Place of Service selected is required.";
export const MEDICALP_IE_TN_ERR = "Tooth Number selected is required.";
export const MEDICALP_IE_BENEFIT_ERR = "Benefit Plan selected is required.";
export const MEDICALP_IE_RANGE_ERR =
  "Procedure/Revenue Range or Procedure/Revenue List Num required when Include or Exclude selected";
export const MEDICALP_IE_DIAG_ERR = "Invalid diagnosis code.";
export const MEDICALP_IE_DIAG_GRT_ERR =
  "From Value must be less than or equal to Thru Value.";
export const MEDICALP_IE_NA_ERR =
  "Procedure/Revenue Range not allowed when Procedure/Revenue List Numbers are selected";
export const MEDICALP_IE_MOD_ERR =
  "Modifiers can only be entered when a from and thru procedure range is entered.";
export const MEDICALP_IE_MODDUP_ERR = "Duplicate Modifiers are not allowed.";
export const MEDICAL_CRITERIA_LIMIT_DUP =
  "UR Parameter entered is already in the system for selected Medical Criteria. Duplicate UR Limit Parameters are not allowed.";
export const MEDICAL_CRITERIA_CONTRA_DUP =
  "UR Parameter entered is already in the system for selected Medical Criteria. Duplicate UR Contraindicated Parameters are not allowed.";
export const INVALID_PARAM_NUM = "Invalid Limit Parameter Number entered";
export const INVALID_RANKING_NUM = "Data entered is in the incorrect format.";
export const MEDICALP_IE_PROC_GRT_ERR =
  "From Value must be less than or equal to Thru Value.";
export const MEDICALP_IE_REV_GRT_ERR =
  "From Value must be less than or equal to Thru Value.";
export const PROC_POS_ERR = "Place of Service is required.";

//MEDICAL CRITERIA END

//UR SYSTEM LIST STARTS
export const INVALID_PROC_CODE = "Invalid Procedure code.";
export const INVALID_DIAG_CODE = "Invalid diagnosis code.";
export const INVALID_DIAG_CODE_THRU = "Invalid diagnosis code.";
export const INVALID_PROC_CODE_THRU = "Invalid Procedure code.";
export const INVALID_DESCRIPTION = "Data entered is in the incorrect format.";
export const REQ_FROM = "From is required.";
export const REQ_THRU = "Thru is required.";
export const FROM_THRU_ERR =
  "From Value must be less than or equal to Thru Value.";
export const UR_SYS_LIST_ERR = "UR System List# is required.";
export const DUP_FROM_CODE = "Entered From Code is already added to the List";
export const DUP_THRU_CODE = "Entered Thru Code is already added to the List";
export const INVALID_REV_CODE = "Invalid Revenue Code";
export const Invalid_ProcCode_Error =
  "Invalid Procedure Code: Procedure must be four characters, all numeric.";
export const Invalid_RevCode_Error =
  "Invalid Revenue Code: Revenue must be four characters, all numeric.";
export const ROW_REQ_ERR =
  "At least one detail row is required. Please enter at least one detail row.";
//UR SYSTEM LIST END

export const DUP_RA_ERR =
  "The Document Type, Media type and Claim Type combination exists for this exception code.  Duplicate Document Type, Media Type and Claim Type combinations are not allowed for RA Text Override.";
export const DUP_CLAIM_ROUTING_ERR =
  "The Document Type, Media type and Claim Type combination exists for this exception code.  Duplicate Document Type, Media Type and Claim Type combinations are not allowed for Claim Routing.";
// Procedure code Provider Taxonomy Starts
export const INCORRECT_PROC_ERR = "Incorrect current procedure code";
// Procedure code Provider Taxonomy Ends

export const DUPLICATE_ADJUSTMENT_ERR =
  "Adjustment reason code already exists for claim exception code.  Duplicate Adjustment Reason Codes are not allowed.";
export const DUPLICATE_REASON_CODE =
  "Remark code already exists for claim exception code.  Duplicate Remark Codes are not allowed.";
export const AMT_REQ_ERR = "Amount is required.";
export const RATE_AMT_DEC_ERR =
  "Rate Amount can have only 7 characters before decimal and 2 characters after decimal.";
export const PER_AMT_DEC_ERR =
  "Rate Percentage can have only 5 characters before decimal and 2 characters after decimal.";
export const RATE_ZERO_ERR = "Rate Amount should not be Zero";
export const PROV_TAXO_REQ_ERR = "Provider Taxonomy is required.";
export const RATE_REQ_ERR = "Rate is required.";
export const PROV_COS_REQ_ERR = "Category of Service is required.";

// Procedure code Provider Taxonomy Ends

export const DUB_RATE_SOURCE =
  "A rate for the header criteria already exists. Duplicates rates are not allowed.";
export const DENT_SUSP_DESP =
  "Denied or Suspended dispositions require an Adjustment Reason code and EOB to be entered.";
export const SUSP_DESP_ERR =
  "Suspended dispositions require a Location to be entered.";
export const BEGIN_DT_GRT_END_DT =
  "Begin Date must be less than or equal to End Date.";
export const INCORRECT_PROC_CODE = "Incorrect current procedure code.";
export const MOD_PROC_CODE_REQ =
  "Enter a Procedure Code as Search criteria if Modifier is selected.";

export const OnePOS_Error =
  "At least one detail row is required in Procedure Code / Place of Service.";

export const PROCEDURE_MIN_FIVE = "ProcedureCode length is less than five.";

export const MEMBER_MID_ERR =
  "Member's MID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const MEMBER_RID_ERR =
  "Member's RID is incorrect format due to being the wrong length or it contains non-numeric characters.";

export const SSN_NUMERIC_ERR = "SSN not numeric";
export const ENT_ERR = "Enterprise ID should start with XXM.";
export const MCR_ERR =
  "Medicare ID should have length minimum of 10 and maximum of 12.";

export const AT_ONE_RATE_DETAIL = "At least one Rate Detail row must exist.";
export const RATE_PROV_NET_NOT_EXIST = "Provider not in Network.";
export const RATE_PROV_NOT_FILE = "Provider ID not on the Provider file.";
export const RATE_PROC_NOT_FILE = "Procedure Code not on file.";

export const STATE_DRUG_REQ = "State is required.";
export const SA_EXC_CODE_REQ_ERROR = "SA Exception Code is required.";
export const SA_EXC_TYPE_REQ_ERROR = "Exception Type is required.";
export const SA_EXC_LOC_REQ_ERROR = "Location is required.";
export const SA_EXC_CODE_FORMAT_ERROR = "SA Exception Code should be numeric.";
export const SA_EXC_SA_TYPE_REQ_ERROR = "SA Type is required.";
export const SA_EXC_SUSPEND_REQ_ERR =
  "Either a Location or a User ID is required if a SA Routing Location row is entered.";
export const SA_EXC_CODE_SUSPEND_DUP_ERR =
  "SA Routing Location entered is already in the system for selected SA Exception Code. Duplicate SA Routing Locations are not allowed.";
export const SA_EXC_MEDIA_TY_REQ_ERROR = "SA Media Type is required.";

export const SA_EXC_RSN_CODE_ERR = "Please select SA Reason codes in order.";
export const SA_EXC_RSN_CODE_DUP_ERR =
  "SA Reason Code entered is already in the system for selected SA Exception Code. Duplicate SA Reason Codes are not allowed.";
export const INPATIENT_DRG_ERR = "At least one Rate Detail row must exist.";
export const NON_DRG_RATE_ERR = "At least one Rate Detail row must exist.";
export const PROV_ID_TYPE_CODE_ERR = "Provider ID Type Code is required.";
export const PROV_ID_ERR = "Provider ID is required.";
export const POINT_RATE_ERR = "Point Rate is Required.";
export const ADJUST_FACTOR_ERR = "Adjust Factor is required.";
export const CHARGE_MODE_ERR = "Charge Mode is required.";
export const AMOUNT_ERR = "Amount or Percent must be entered";
export const STATUS_CHANGE_REASON_ERROR = "The Line Item's Reason for Status Change is required if the SA LI Status is \"D-Denied\" or \"V-Voided\"";
export const PROV_BILL_ID_ERR = "Provider Billig ID is required.";
export const END_DATE_LESS_THAN_BEGIN_DATE =
  "The end date must be greater than or equal to the begin date.";
export const UNITS_OF_MEAS_REQUIRED =
  "Units of Measure is Required when Requested Units Entered.";
export const CATEGORY_CD_REQUIRED = "Category Code is required.";
export const SVC_TYPE_REQUIRED = "Svc Type is required.";
export const FROM_SVC_REQUIRED = "From Svc is required.";
export const TO_SVC_REQUIRED = "To Svc is required.";
export const PROF_BEGIN_DATE_INV =
  "Line Item Summary's First Date of Service is invalid.";
export const PROF_END_DATE_INV =
  "Line Item Summary's Last Date of Service is invalid.";
export const PROF_TOTAL_CHARGE_INV =
  "Line Item summary's Total Charge is invalid.";
export const PROF_SERIAL_INV = "Serial # is invalid";
export const MEDCAIR_ALLOW_AMT_INV = "Medicare Allowed Amount is invalid.";
export const MEDI_DEDUCT_AMT_INV = "Medicare Deduct Amount is invalid.";
export const MEDI_COINS_AMT_INV = "Medicare Coins Amount is invalid.";
export const MEDI_PAID_AMT_INV = "Medicare Paid Amount is invalid.";
export const DRUG_UNIT_PRICE_ERR = "Drug Unit Price is invalid.";
export const NATIONAL_DRUG_UNIT_COUNT_ERR =
  "National Drug Unit Count is invalid.";
export const PRECRIPTION_DATE_ERR = "National Drug Unit Count is invalid.";

export const CARRIER_ERR = "Carrier ID is required.";
export const NATION_ERR = "National Payer ID is required.";
export const GROUP_ERR = "Group ID is required.";
export const NUM_ERR = "Number is required.";
export const NUM_TYPE_ERR = "Number Type is required.";
export const NAME_ERR = "Name is required.";
export const SEARCHBY_ERR = "Search By  is required.";
export const LAST_ERR = "Last name is required.";
export const WORKER_ERR = "Worker ID is required.";
export const CITY_ERR =
  "Either City or State must be entered while entering the City/State.";
export const LEVEL_OF_CARE_ERROR =
  "A Level of Care must be selected if the Charge Mode is LTC.";
export const LEVEL_OF_CARE_CHARGE_MODE_ERROR =
  "A Level of Care should not be selected if the Charge Mode is something other than LTC.";
export const POINT_OF_RATE_INVALID_ERROR =
  "Point Rate format should be #######.## or #######.";
export const ADJUST_FACTOR_FORMET_ERROR =
  "Adjust Factor format should be ###.###### or ###.";
export const AMOUNT_INVALID_ERROR = "Currency format should be #########.##";
export const PERCENT_INVALID_ERROR =
  "Percent format should be ####.##### or ####";
export const SEARCH_BY = "Search By is required.";
export const MEMBER_ID_TYPE_ERROR = "Member ID Type is required.";
export const EOMB_DATE_INV = "EOMB Date is invalid.";
export const BENEFIT_PLAN_AMOUNT_ERR =
  "Rate Amount cannot be more than 7 characters without decimal.	";
export const CARRIER_ID_REQUIRED = "Carrier ID is required.";
export const CARRIER_ID_FORMAT = "Data entered is in the incorrect format.";
export const PROVIDER_NOT_FILE = "Provider not in Network.";
export const PROCEDURE_NOT_FILE = "Procedure Code not on file.";
export const LINE_ITEM_MAX_ERR =
  "A maximum of 50 lines may be added to a claim. The number of lines exceeds 50.";
export const DATE_OVERLAP =
  "There cannot be overlaps between date spans across the detail rows.";
export const UNABLE_WORK_BEGIN_ERR =
  "Unable to Work Begin Date is Invalid.";
export const UNABLE_WORK_END_ERR = "Unable to Work End Date is Invalid.";
export const VISIT_HOSP_BEGIN_ERR = "Hospital Begin Date is Invalid.";
export const VISIT_HOSP_END_ERR = "Hospital End Date is Invalid.";
export const OUT_LAB_AMT_ERR = "Visit Out Lab / Amt is Invalid.";
export const LOB_CODE_FORMAT_ERROR = "Enter a Valid Lob Code.";
export const LOB_NAME_FORMAT_ERROR = "Enter a Valid Lob Name.";
export const TPL_MASS_CHANGE_TYPE_ERR = "Mass Change Type is required.";
export const Starts_With_Contains_Error_Network =
  "Search criteria must be at least 2 characters in length. Please enter at least 2 characters and resubmit";
export const TP_ID_REQ_ERR = "Trading Partner ID is Required.";
export const TP_ID_INV_ERR = "Trading Partner ID is Invalid.";
export const SEARCHFOR_ERR = "Search For is Required.";
export const CLASSTYPE_ERR = "Classification Type ID is Required.";
export const LNAME_ERR = "Last Name is Required.";
export const BNAME_ERR = "Business Name is Required.";
export const SCREENING_TYPE_ERR = "Screening Type is Required.";
export const GENERIC_SYSTEM_ERROR =
  "There was an error processing the request. Please retry the transaction.";
export const DESCRIPTION_IS_REQUIRED = "Description is required.";
export const WORKUNIT_IS_REQUIRED = "Work Unit Level is required.";
export const DOC_TYPE_IS_REQUIRED = "Document Type is required.";
export const FILE_IS_REQUIRED = "File is required.";
export const ONLY_ONE_FILE_SELECT = "Only 1 file can be uploaded at a time.";
export const MAX_ONE_ATTACHMENT_ALLOWED = "Only one attachment is allowed.";
export const INVALID_FILE_TYPE =
  "The files you are attempting to attach are an invalid file type. Please contact Technical Support for information on valid file types.";
export const ZERO_MB_FILE =
  "The file uploaded is empty/zero bytes. The attachment will not be saved.";
export const MAX_FILE_SIZE_EXCEEDED =
  "The file(s) you are attempting to attach is/are larger than the maximum allowed size of 10 mb. Please try again by reducing the file size or by uploading the files separately.";
export const ERROR_IN_DOWNLOADING_FILE =
  "Error while downloading the file. Please contact Technical Support";
export const FILE_TYPES = ["application/pdf", "application/msword"];
export const TPL_CONF_CODE_ERR = "Confirmation Code is required.";
export const TPL_FRM_CARR_ID_ERR = "From Carrier ID is required - Please enter valid From Carrier ID";
export const TPL_GROUP_FRM_ID_ERR =  "From Group ID is required.";
export const TPL_TERMINATION_DT_ERR =  "Termination date is required - please enter the termination date.";
export const TPL_TO_CARR_ID_ERR =
  "To Carrier ID is required - Please enter valid To Carrier ID";
  export const TPL_TO_BEGIN_DATE_ERR =  "To Begin Date is required.";
export const TPL_INVALID_CARR_ID_ERR = "From Carrier id is required - Please enter valid from carrier id."
export const TPL_INVALID_GRP_ID_ERR = "From Group id is required."
export const TPL_INVALID_DATE_ERR = "Date entered in incorrect format."
export const LINE_RATE_AMT_INV = "Rate Amount is invalid.";
export const LINE_NON_COV_CHG_INV = "Non Covered Charges Amount is invalid.";
export const INPATIENT_BASE_RATE_AMT_INV =
  "Inpatient Pricing Base Rate Amount is invalid.";
  export const LOCATION_CODE_REQ =
  "Location Code is required.";
  export const LOCATION_TEXT_REQ =
  "Location Text is required.";
  export const LOCATION_CODE_INVALID =
  " Location Code Must Be 3 Numeric Characters.";

export const CONTACT_TYPE_REQD = "ContactType is Required.";
export const SIGNIFICANCE_REQD ="Significance is Required.";
export const FIRST_NAME_REQD ="FirstName is Required.";
export const LAST_NAME_REQD ="LastName is Required.";
export const BEGIN_DATE_REQD ="Begin date is Required.";
export const END_DATE_REQD ="EndDate is Required.";
export const CONTACT_STATUS_REQD = "ContactStatus is Required.";
export const INCORRECT_FORMAT ="Please enter the data in correct format.";
export const DOD_LT_DOB ="Date of Death cannot be less than the Date of Birth";
export const EDT_LT_BDT ="End Date must be greater than or equal to begin date.";
export const BEGIN_DATE_PRIOR_ERR ="Begin Date may not be prior to the current system date.";
export const CARRIER_NAME_REQUIRED = "Carrier Name is required.";
export const AS_DATE_INVALID="Invalid As of Date, please enter in MM/DD/YYYY format";
export const MCO_PROVIDER_ID_REQ='MCO ProviderID is required.';
export const PAP_PROVIDER_ID_REQ='PAP ProviderID is required.';
export const PROVIDERID_NOT_VALID='Provider ID is not valid.';
export const PROVIDERID_SPEL_CHAR_ERR='Provider ID must not contain special characters.';
export const NETWORK_NAME_REQ='Network Name is required.';
export const NETWORK_TYPE_REQ='Network Type is required.';
export const ADDS_PROV_NETWORK='At least one address is required for a Provider Network.';
export const NETWORK_ID_SPEL_CHAR='Network ID must not contain special characters.';
export const NETWORK_NAME_SPEL_CHAR='Network Name must not contain special characters';
export const PROVIDER_MQ_REQ='If the entered Network Type is Managed Care or PAP, then the Provider ID is required.';
export const MCO_PROVIDER_TYPE_CHECK='If the MCO Provider ID is entered, then the provider must be an MCO provider type.';
export const PAP_PROVIDER_TYPE_CHECK='For network type of Q, the Provider ID entered must be associated to a PAP Provider Type.';
export const NETWORK_DUPLICATE_CHECK='Duplication of the same Network ID is not allowed.';
export const INVALID_REC_DATE='Received Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_REQ_BGN_DATE='Requested Begin Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_REQ_END_DATE='Requested End Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_APP_BGN_DATE='Approved Begin Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_APP_END_DATE='Approved End Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const SUPPLEMENTAL_PROVIDER_ID_REQ ="Supplemental Provider ID is Required.";
export const PROVIDER_ID_TYPE_REQ ="Provider ID Type is Required.";
export const DOB_ERR ="Date of Birth cannot be greater than the Current Date";
export const REQUESTING_ERR = "Requesting Provider ID and Type are both required if either is entered.";
export const EVENT_ERR = "Event Provider ID Type and Provider ID are both required if either are entered.";
export const SERVICE_ERR ="Servicing Provider ID is required.";
export const SERVICE_TYPE_ERR ="A Servicing Provider ID Type is required.";
export const VALID_HEADER_STATUS_ERR = "Please enter a Header Status of A (Approved), D (Denied), V (Voided), or P (Pended).";
export const Received_Dt_Future_ERR ="Received Date cannot be greater than the Current Date";
export const HICN_REQ_ERROR="HICN is Required.";
export const MEDICARE_COVERAGE_REQ_ERROR="Medicare Coverage is Required.";
export const TOOTH_ERR = "Tooth Number is Required.";
export const QUALIFIER_TYPE = "Service Quantity Type is Required.";
export const QUANTITY = "Service Quantity is Required.";
export const LINE_FACILITY_TYPE = "Line Facility Type is required if Line Facility Type Qualifier is present.";
export const LINE_FACILITY_TYPE_QUALIFIER = "Line Facility Type Qualifier is required if Line Facility Type is present.";
export const ADJ_CNTRL_INVLD_ERR = "Please enter valid sequence number.";
export const ADJ_CNTRL_REQ_ERR = "Service Sequence is required.";
export const ADJ_TYCD_REQ_ERR = "Claim Type is required.";
export const ADJ_BTYCD_REQ_ERR = "Batch Document Type is required.";
export const MEDICARE_COVERAGE_INVALID="Duplicate Medicare Coverage.";
export const VALIDATE_MEMBER_ID = "Please validate Member ID";
export const BEFORE_BIRTH_DATE = "Medicare span Begin date cannot be less than Member Date of Birth.";
export const MEMBER_ID_NOT_INDB_ERR = "Member ID not found for the Member ID Type selected on the database."
export const Overlaps_Btw_Detail_Rows_Dates_same_coverage="Medicare Coverage Period cannot be overlapping to existing Medicare Coverage Period for same Medicare Type";
//TPL Add/update
export const COVERAGE_REQ_ERR= "Coverage must be selected when Annual Exhausted Date is entered and/or Lifetime Exhausted Indicator is set to 'Yes'.";
export const ANNUAL_EXAUST_DATE_ERR = "Annual Exhausted Date must be populated or Lifetime benefits exhausted indicator must be set to Yes when coverage is selected.";
export const GTTHAN_SYSTEM_DATE_ERR = "Annual exhausted date cannot be greater than System Date.";
export const MULTIPLE_COVERAGE_CODE_ERR = "Policy Coverage Code cannot be selected multiple times for the same policy period.";
export const STATUS_CODE_REQ_ERROR = "Status Code is required.";
export const STATUS_CODE_ACTIVE_ERR = "The Covered Individual's status may not be set to Active when the policy is pended.";
export const COVERED_INDV_BEGINDATE_ACTIVE_ERR = "Begin Date is required when Covered Individual is active.";
export const COVERED_INDV_ENDDATE_ACTIVE_ERR = "End Date is required when Covered Individual is active.";
export const BEGIN_LESS_END_DATE_ERR = "Begin Date must be less than or equal to End Date.";
export const STATUS_CODE_VOIDED_ERR = "Covered Individual Information cannot be saved because Covered Individual Information is being voided and one or more other fields has been changed.";
export const PH_ADDRESS_REQ = "Policyholder Address is required.";
export const PH_CITY_STATE_ZIP_REQ = "City, State and Zip are required.";
export const SAVE_TPL_REQ_FIELDS = "Member ID must be entered when Carrier ID and Policy are not entered";
export const ECPLYER_ADDRESS_VALIDATE = "Address Type, Address Line1, City, State and Zip cannot be spaces when Employer Name is entered";
export const ADD_LINE1_REQ = "Employer address Line1 is required.";
export const CARRIER_NOT_DB='Carrier ID not found in database - Please enter correct Carrier ID.';
export const GENERATE_270_CARRIER_ID_ERR="Carrier is not associated with Submitting Trading Partner or associated Submitting Trading Partner is not active.";
export const GENERATE_270_SUCCESS_MSG = "Generate 270 request created successfully.";
export const HICN_FORMAT_ERROR="Medicare ID/HIC number is in an invalid format.";
export const CMBI_FORMAT_ERROR="Please enter Current MBI in correct format.";
export const MBI_FORMAT_ERROR="MBI entered is not in valid format";
export const EVENT_PRV_TAX_ERR = "An Event Provider Taxonomy Code is Not Allowed with the Entity Code Selected.";
export const SERVICE_PRV_TAX_ERR = "Service Provider Taxonomy Code is Not Allowed with the Entity Code Selected.";
export const PRV_ZIP_EXTN = "Zip Code is required if Zip+4/Extension is entered.";
export const ASS_PAPER_WORK_ATT = "Attachments at the line level must have a type and delivery method.";
export const POLICY_NUM_REQ = "Policy Number is required.";
export const RESOUCE_TYPE_REQ = "Resource Type is required.";
export const POLICY_BEGIN_DT_REQ = "Policy Begin Date is required.";
export const POLICY_END_DT_REQ = "Policy End Date is required.";
export const TPL_COVERAGES_REQ = "Please select at least one coverage for the Policy to activate Policy Period.";
export const TPL_COV_IND_REQ = "At least one valid Covered Individual must be entered and active to activate the policy period.";
export const TPL_PERIOD_OVERLAP_ERROR = "Policy Coverage Period cannot be overlapping to existing Policy Coverage Period.";
//Void Adjustments
export const INVALID_TCN_FORMAT = "Please enter the tcn in correct format.";
export const REPLACEMENT_TCN_IN_USE = "The Replacement TCN is already present. Use a different document number.";
export const TCN_DOESNT_EXIST_IN_DB = `The TCN to be Replaced or Voided does not match a previously "Paid" or "Denied", "To be Paid" or "To be Denied" Claim in history.`;
export const PROV_REQ = "Provider ID Type is required.";
export const BILL_PROV_REQ = "Billing Provider ID  is required.";
export const MEM_REQ = "Member ID is required.";
export const TCN_VOID_REQ = "TCN to be voided/Replaced is required.";
export const VOID_REP_SAVE_MSG = "Void / Replaced claim information saved with exceptions.";
export const VOID_REP_DEL_MSG = "Void / Replaced claim deleted successfully.";
export const VOID_REP_SAVE_CMSG = "The VoidReplacement Claim is saved.";
export const VOID_REP_DEL_CMSG = "The VoidReplacement Claim is Deleted.";
export const REASON_REQ = "Reason is required.";
export const TPL_COPY_VALIDATION = "Policy Copy can not be performed because all associated Covered Individuals have been Terminated or Voided before the Policy End Date";
export const SA_RQ_PROVIDER_ERROR = "At least one Requesting Provider ID is required.";
export const SA_RQ_PROVIDER_ID_TYPE_ERROR = "Requesting Provider IDs must include Medicaid and/or NPI#";
export const SA_SV_PROV_LI_NUM = "Prov Line# cannot be zero for SV providers";
export const EV_ENTITY_TYPE  = "Event Provider Entity Type is Required";
export const SV_ENTITY_TYPE  = "Service Provider Entity Type is Required";
export const RQ_ENTITY_TYPE  = "Request Provider Entity Type is Required";
export const SV_ENTITY_CODE  = "Service Provider Entity Code is Required";
export const EV_ENTITY_CODE  = "Event Provider Entity Code is Required";
export const RQ_ENTITY_CODE  = "Request Provider Entity Code is Required.";
export const EV_MAX_ID = "Event Provider max supplemental ID's is 7";
export const SV_MAX_ID = "Servicing Provider max Supplemental ID's is 8";
export const EV_PROV_SEQ_OCCU = "Event Provider ID's Exceeds 14 or not sequential";
export const TRANS_LEVEL = "Trans Level is Required.";
export const PROV_ID_TYPE = "Provider Id Type is Required.";
export const PROV_ID = "Provider Id is Required.";
export const ENTITY_CODE = "Entity Code is Required.";
export const ENTITY_TYPE = "Entity Type is Required.";
export const UNIT_OF_MEASURE = "Units of Measure is Required when Requested Units Entered.";
export const TPL_NAVIGATION_REQ = "Please Enter either Invoice or Member ID or TCN.";
export const LINE_ITEM_ORALCAVITY_ERR = "Tooth Oral Cavity must be entered contiguously starting with 1.";
export const LINE_ITEM_TOOTH_SURFACE = "Tooth Surfaces must be entered contiguously. starting with 1.";
export const BEGIN_TIME_INVALID="Begin Time format is invalid.";
export const END_TIME_INVALID="End Time format is invalid.";
export const USER_ID_NAME_ERR="Please enter either User ID or Last Name for the search.";
export const EVENT_GT_DT_ERROR="End Date must be greater than or equal to begin date.";
export const EV_PROV_ID_OCCUR = "Duplicate Event Provider ID Type for occurrence.";
export const SV_PROV_ID_OCCUR = "Duplicate Servicing Provider ID Type for occurrence.";
export const GLOBAL_AUDIT_SEARCH_ERR = "(User ID/Job ID or User Name) OR (Table Name and Field Name) OR (Key Type and Key Value) is required.";
export const INVOICE_REQ = "Invoice Number is required.";
export const INVOICE_REQ_CHECK = "Alteast One Checkbox should be Selected.";
export const KEY_VALUE_ERROR = "Key is required when Key Type is entered.";
export const KEY_TYPE_ERROR="Key Type is required when Key is entered.";
export const APPROVAL_PERIOD_ERROR = "Approval period is Required.";
export const APPROVAL_LENGTH_ERROR = "Approval length is Required.";
export const APPROVAL_UNITS_ERROR = "Approval units is Required.";

// Letter Assignment Error Messages
export const RANK_ERROR = "Rank is Required.";
export const RANK_UNIQUE_ERROR = "Rank should be unique.";
export const LETTER_ASSIGNMENT_TITLE_ERROR = "Letter Assignment Title is Required.";
export const SA_ANCILARY_FACILITY_TYPE_ERR = "If Facility Type is entered then Facility Type qualifier is required.";
export const SA_ANCILARY_FACILITY_TYPE_QUALIFIER_ERR = "If  Facility Type Qualifier is entered then Facility type is required";
export const PRIOR_ERR = "Annual exhausted date cannot be greater than System Date.";

// SA Validate API error
export const VALIDATE_LAST_NAME_ERROR = "The Last Name entered does not match the Last Name on the Member file for the entered MID.";
export const VALIDATE_FIRST_NAME_ERROR = "The First Name entered does not match the First Name on the Member file for the entered MID.";
export const VALIDATE_MI_ERROR = "The MI entered does not match the MI on the Member file for the entered MID.";
export const VALIDATE_SUFFIX_ERROR = "The Suffix entered does not match the Suffix on the Member file for the entered MID.";
export const VALIDATE_DOB_ERROR = "The Date of Birth entered does not match the Date of Birth on the Member file for the entered MID.";
export const VALIDATE_AGE_ERROR = "The Age entered does not match the Age on the Member file for the entered MID.";
export const VALIDATE_SSN_ERROR = "The SSN entered does not match the SSN on the Member file for the entered MID.";
export const CLOSURE_DATE_REQ = "Closure Date must be entered when Closure Reason is selected.";
export const CLOSURE_REASON_REQ = 'Closure Reason must be selected when Close Date is entered.';
export const MASS_ADJ_EXCEPTION_CODES_CHECK = "Exception Code must be combined with Paid Date and/or Begin DOS. No other criteria types allowed.";
export const RECOVERY_REASON_REQ = "Recovery Reason is required.";
export const RECOVERY_REASON_NOTALLOW = "Recovery Amount must be 0 when Recovery Reason selected starts with 'D**' or 'R99' or 'S01'";
export const AMOUNT_FCN_NOT_REQ = "FCN and Recovery Amount not allowed when Recovery Reason R99, D* or S* is entered.";
export const AMOUNT_FCN_REQ = "Recovery Amount must be greater than 0 when Recovery Reason selected starts with 'R**' but not 'R99'";
export const FCN_REQUIRED = "Valid FCN must be entered when Recovery Amount is greater than 0.";
export const RECOVERY_RES_R = "Recovery Method, Recovery Date, Recovery Amount, Recovery Reason and FCN must be present when Recovery Reason begins with 'R'.";
export const RECOV_AMT_REQ = "Recovery Amount must be greater than 0 when Recovery Reason selected starts with 'R**' but not 'R99'";
export const FIXED_AMT_REQ = "Fixed Amount cannot be equal to spaces or zeroes";
export const BILL_RES_RED = "Recovery Reason, Recovery Method, Recovery Amount and FCN all required when one or more fields are entered.";
export const INVALID_EMPLOYER="Employer Name, Address Type, City, State and Zip are required when Employer Address Line 1 is entered."

//notes
export const NOTES_ADD = "Your note(s) will be saved when you select the save link at the top of the page."
export const NOTES_NO_REC= "No records found for the search criteria entered.";

// Login Erro Messages
export const USERNAME_REQ_ERR = "User ID is required.";
export const USERNAME_MIN_LEN_ERR = "User ID must contain minimum of 6 Characters.";
export const USERNAME_EXIST_ERR = "User ID or Password supplied is invalid.  Please provide the valid credentials";
export const PASSWORD_REQ_ERR = 'Password is required.';
export const USER_LOCKED_ERR = 'Your account has been LOCKED. Please Contact System Administrator or try after sometime';
export const USER_LOCKED_ERR_MESSAGE = 'Account Locked, contact Administrator';

//Change Password
export const PASSWORD_NEW_REQ = 'New Password is required.';
export const PASSWORD_CONFIRM_REQ = 'Confirm New Password is required.';
export const PASSWORD_REQ = 'Current Password is required.';
export const PASSWORD_LEN = 'The password must contain at least 10 characters.';
export const PASSWORD_3_OF_4 =
  'The password must not contain characters other than alphanumeric characters and the following non-alphanumeric characters: !, $, #, or %.';
export const PASSWORD_5_TIMES =
  'The new password must not be one of the 5 previously used passwords.';
export const PASSWORD_AND_CONFIRM_SAME = 'New and Confirm New passwords do not match. Please try again.';
export const NEW_PWD_CURRENT_PWD_ERR = 'The New Password you entered is the same as your Current Password.  Please try again.';
export const PREVIOUS_FIVE_PWD ="The new password must not be any of the user’s previous 5 passwords."
export const PASSWORD_POLICY_NOT_MATCHED ="The new password entered does not follow the password policies."
export const CURRENT_PASS_INVALID ="Current password provided is incorrect. Please try again."
export const INVALID_CREDENTIALS = 'User ID or Password supplied is invalid.  Please provide the valid credentials.'
export const ACCOUNT_DEACTIVATED = 'Login attempt failed. Your account has been deactivated. Please contact your Organization Administrator.'
export const ACCOUNT_LOCKED = 'You cannot login because your account is locked. Please contact your Organization Administrator.'
export const PASSWORD_EXPIRED = 'Your password is expired. Please change your password.'
export const PASSWORD_EXPIRED_SEVEN_DAYS = 'Your Password is about to expire in 7 days. Change Password to continue using app features.'
export const FIRST_TIME_LOGIN = 'You have a one time, single use password, you must change it to gain normal access.'
export const CURRENT_PASSWORD_FAIL = 'Login failure'
export const USER_LOCKED = 'User Locked Out.'
export const ACC_LOCKED = 'Your account has been locked.'
export const ACCOUNT_PWD_EXP = 'Your password has expired. Please contact service desk to reset your password'
export const PASSWORD_EXPIRED_ERR_TYPE = 'PASSWORD-EXPIRED'
export const LOGIN_FAILURE = 'Login failure'
export const AFTER_ONE_MORE_ATTEMPT = 'Warning: You will be locked out after 1 more failure(s).'
export const AFTER_TWO_MORE_ATTEMPT = 'Warning: You will be locked out after 2 more failure(s).'
export const AUTHENTICATION_FAILED = 'Authentication Failed'
export const NO_SPECIAL_CHAR_UN_PWD = 'UserName & Password Can not be Empty or NULL. USERID can only containts Alphanumeric, Hyphen, Underscore, Or Period and Must be Between 6-16 Length'
export const ACC_INACTIVE = 'Your account has been INACTIVE. Please Contact System Administrator'
export const GRACE_LOGIN_EXHAUSTED = 'Password Expired and Grace Login Exhausted. Please Contact your Administrator to Reset your password.'
export const CREDENTIAL_NVALID = 'Invalid Credentials. Please Contact System Administrator'
export const UNAUTHORIZED ='Unauthorized';
export const DEACTIVATED ="Login attempt failed. Your account has been deactivated. Please contact your Organization Administrator.";