export const VALIDATE_USER = "VALIDATE_USER";
export const SEARCH_USER = "SEARCH_USER";
export const RESET_USER = "RESET_USER";
export const RESET_ALL_STATE = "RESET_ALL_STATE";
export const SEARCH_USER_BY_ID = "SEARCH_USER_BY_ID";
export const RESET_USER_BY_ID = "RESET_USER_BY_ID";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const RESET_USER_DETAILS = "RESET_USER_DETAILS";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const DISPATCH_USER_DATA = "DISPATCH_USER_DATA";
export const GET_MY_ACCOUNT_DATA = "GET_MY_ACCOUNT_DATA";
export const FORCE_PASSWORD_CHANGE = 'FORCE_PASSWORD_CHANGE';