import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./theme.scss";
import "./App.scss"
import axios from "axios";
import qs from "qs";
import { useHistory } from 'react-router-dom';
import FooterLinks from "./SharedModules/Layout/FooterLinks";
import LoginPage  from './Modules/UserLogin/LoginPage'
import NewLogin from "./Modules/UserLogin/NewLogin";
import Spinner from './SharedModules/Spinner/Spinner';
import ManageUser from "./Modules/Manage_User";
import Header from "./Modules/Header";
import { CLAIMS_APP_CODE, CMdSPortals, ERR_PROCESSING_REQ, X_CMDS_APP_CODE } from "./SharedModules/AppConstants";
import { getMyAccDetailsAction, setForcePasswordChange } from './Modules/UserSearch/action';
import { MY_ACCOUNT, USER_SEARCH } from "./SharedModules/Navigation/RouteConstants";
import * as ErrorMsgConstants from "./SharedModules/Messages/ErrorMsgConstants";
import {safeLocalStorage } from './SharedModules/utility/utilityFunction';
import * as TextConst from "./Modules/UserSearch/ManageAccountConstants";
import ChangePassword from './Modules/UserLogin/ChangePassword';
import * as serviceEndPoint from './SharedModules/services/service'; 
import moment from 'moment';


const HomePage = (props) => {  
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const uid = params.get("uid");
  const scode = localStorage.getItem("code");
  const checked = localStorage.getItem("Checked");
  const [user, setUser] = React.useState("");
  const [isPassword, setIsPassword] = React.useState(false);
  const [loginState, setLoginState] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(false || localStorage.getItem('accessToken') || uid || code || scode);
  const [appList, setAppList] = React.useState([]);
  const [downtime, setDownTime] = React.useState(false);
  const [upComingDowntime, setUpComingDownTime] = React.useState(false);
  const [donwTimeMsg, setDownTimeMsg] = React.useState(process.env.REACT_APP_DOWNTIME_MESSAGE);
  const getDetails = id => dispatch(getMyAccDetailsAction(id));
  const userDetailsData = useSelector(state => state.userManagement?.myAccDetailsResponse);
  const history1 = useHistory();

  const [footerLinks, setFooterLinks] = React.useState(false);
  const [footerLinksName, setFooterLinksName] = React.useState("");  
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [warningMessages, setWarningMessages] = React.useState([]);
  const [infoMessages, setInfoMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [logInType, setLoginType] = React.useState(null);

  const [showLink,setShowLink] = useState(["MyAcc"]);
  const defaultFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }; 
  const [formValue, setformValue] = React.useState(defaultFormValue);
  const [changeState, setChangeState] = React.useState(false);
  const [successData, setSuccessData] = React.useState('');
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
    count: 0,
    void: false,
    accessBox: false,
    linksBox: true,
    updatesBox: true,
  });
  const [
      {
        invalidUsrPassErr
      },
      setShowErrors,
    ] = React.useState(false);
  const redirectToLandingPage = () => {
    setLoginState(false);
    setIsLogin(false);
    setIsPassword(false);
    setInfoMessages([]);
    setWarningMessages([]);
    setErrorMessages([]);
    logOut();
    setChangeState(false);
    setValues({
      username: "",
      password: "",
      voiChk: false,
    });
  };

  const tokenInterval = () => {
    let expTime= localStorage.getItem('expiresInSeconds');
    if(tokenIntervalID){
      clearTimeout(tokenIntervalID)
    }
    if(expTime){
    tokenIntervalID = setTimeout(() => {
      refreshToken(
        localStorage.getItem("refreshToken"),
        localStorage.getItem("username")
      );
    }, expTime* 1000 - 10000);
  }
  };
  const getDownTime = () => {
    
    var config = {
      method: "get",
      url: process.env.REACT_APP_DOWNTIME,
      headers: {
        'X-CMDS-APP-CODE' : CLAIMS_APP_CODE,
        Authorization: localStorage.getItem("accessToken"),
        Username: localStorage.getItem("username"),
      },
    };

    axios(config)
      .then(function (response) {
        let dt = new Date();
        let sdt, edt, ndt, duration, durationH, durationM;
        if(response.data?.startDateTime){
          duration = response.data?.duration;
          if(duration){
            durationH = +duration.split(':')[0];
            durationM = +duration.split(':')[1];
          }          
          sdt = new Date(response.data?.startDateTime);
          edt = new Date(sdt);
          edt.setHours(edt.getHours() + durationH);
          edt.setMinutes(edt.getMinutes() + durationM);
          edt = new Date(edt);
          ndt = new Date(sdt);
          ndt.setDate(sdt.getDate() - (+process.env.REACT_APP_DOWNTIME_NOTIFY_DAYS));
          ndt = new Date(ndt);
          setDownTime((new Date(edt) > new Date(dt)) &&  (new Date(dt) > new Date(sdt)));
          setUpComingDownTime((new Date(ndt) < new Date(dt)) && (new Date(dt) < new Date(edt)));
          setDownTimeMsg(donwTimeMsg.replace('sdt', sdt.toLocaleString()).replace('edt', edt.toLocaleString()));
        }                              
      })
      .catch(function (error) {
        console.log("error=>",error,error?.data,error?.status);
      });
  };

  const storeCred = (a, r, u, e, s, isRefresh) => {
    clearInterval(tokenIntervalID);
    postToCMDSPortals({
      accessToken: a,
      refreshToken: r,
      username: u,
      expiresInSeconds: e,
      ssoToken: s,
    });
	document.cookie = `iPlanetDirectoryPro=${s}; Domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Path=/;`;
    localStorage.setItem("accessToken", a);
    localStorage.setItem("refreshToken", r);
    localStorage.setItem("username", u);
    localStorage.setItem("expiresInSeconds", e);
    tokenInterval();
    if(!isRefresh){
      getDownTime()
    }
  };
  const storeAppInfo = (l = []) => {
    localStorage.setItem("applist", JSON.stringify(l));
  };

  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const submitSuccess = () => {
    setInfoMessages([]);
    setWarningMessages([]);
    setSuccessMessages([]);
    setErrorMessages([]);
    setspinnerLoader(true);
      const data = {
        "password": changeState ? formValue.newPassword : values.password,
        "username": values.username.toUpperCase()
      };
      axios.post(`${serviceEndPoint.USER_LOGIN_ENDPOINT}`, data)
        .then(res => {
          if (res.data.success === true) {
            setspinnerLoader(false);
            let d = parseJwt(res.data?.data);
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('username', d?.username);
            localStorage.setItem("user", `${d.lastname}, ${d.firstname}`);
			      localStorage.setItem("logInType", 'NORMAL_LOGIN');
            localStorage.setItem('impStateInd', process.env.REACT_APP_IMP_STAT_IND)
            setSuccessData(d);
            storeAppInfo(d?.applist || []);
            setAppList(d?.applist || []);
            setLoginState(true)
            safeLocalStorage(
              'set',
              'loginState',
              JSON.stringify({
                loginUserName: d?.username,
                loginUserId: d?.userId,
                userPrivileges: d?.roles.split(','),
                time: Date.now(),
                applist: d?.applist,
                passwordExpiry: d?.passwordExpiry,
                firstName: d?.firstname,
                lastName: d?.lastname,
                forcePasswordChange: d?.forcePasswordChange,
                locked: d?.locked,
                email:d?.email,
                accessToken:d?.accessToken,
                refreshToken:d?.refreshToken
              })
            );
            storeCred( res.data?.accessToken,
              res.data?.refreshToken,
              d?.username,
              res.data?.expiresInSeconds,
              res.data?.ssoToken);
          } else {
            setspinnerLoader(false);
            window.localStorage.clear();
            window.sessionStorage.clear();
            if(res.data.success === false &&
              (res.data?.errorMessage === ErrorMsgConstants.LOGIN_FAILURE
                || res.data?.errorMessage === ErrorMsgConstants.AFTER_ONE_MORE_ATTEMPT
                || res.data?.errorMessage === ErrorMsgConstants.AFTER_TWO_MORE_ATTEMPT
                || res.data?.errorMessage === ErrorMsgConstants.NO_SPECIAL_CHAR_UN_PWD
                || res.data?.errorMessage === ErrorMsgConstants.AUTHENTICATION_FAILED
                || res.data?.errorMessage === ErrorMsgConstants.CREDENTIAL_NVALID)){
              setShowErrors({
                invalidUsrPassErr : true
              }); 
              setInfoMessages([]);
              setWarningMessages([]);
              setSuccessMessages([]);
              setErrorMessages([ErrorMsgConstants.INVALID_CREDENTIALS]);
            }
            if(res.data?.success === false &&
              res.data?.errorMessage === ErrorMsgConstants.ACC_INACTIVE){
              setInfoMessages([]);
              setWarningMessages([]);
              setSuccessMessages([]);
              setErrorMessages([ErrorMsgConstants.ACCOUNT_DEACTIVATED]);
            }
            if(res.data?.success === false && (res.data?.errorMessage === ErrorMsgConstants.USER_LOCKED
            || res.data?.errorMessage === ErrorMsgConstants.ACC_LOCKED
            || res.data?.errorMessage === ErrorMsgConstants.GRACE_LOGIN_EXHAUSTED)){
              setInfoMessages([]);
              setWarningMessages([]);
              setSuccessMessages([]);
              setErrorMessages([ErrorMsgConstants.ACCOUNT_LOCKED]);
            }
            if(res.data?.success === false && res.data?.errorType === ErrorMsgConstants.PASSWORD_EXPIRED_ERR_TYPE
            || res.data?.errorMessage === ErrorMsgConstants.ACCOUNT_PWD_EXP){
              setLoginType('');
              setIsPassword(true);
              setInfoMessages([]);
              setWarningMessages([]);
              setSuccessMessages([]);
              setErrorMessages([ErrorMsgConstants.PASSWORD_EXPIRED]);
            }
            if(res.data?.success === false && res.data?.errorType === ErrorMsgConstants.UNAUTHORIZED && res.data?.errorMessage === ErrorMsgConstants.ACC_INACTIVE){
              setInfoMessages([]);
              setWarningMessages([]);
              setSuccessMessages([]);
              setErrorMessages([ErrorMsgConstants.DEACTIVATED]);
            }
          }
        })
        .catch(e => {
          setspinnerLoader(false);
          console.log("failure in catch")
          setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION])
        });
  }

  let userData = JSON.parse(localStorage.getItem("loginState"));
  let tokenIntervalID = null;

  const getLWN = () => {
    return JSON.parse(localStorage.getItem("localWindowName") || "[]");
  };


  const closeWindows = () => {
    let lwn = getLWN();
    for (let i = 0; i < lwn.length; i++) {
      let x = window.open("", lwn[i]);
      x?.close();
    }
    if(window.name != CMdSPortals ){
      if(localStorage.getItem('username')){
        if (lwn.indexOf(window.name) == -1) {
          if(window.opener){
            window.open("",CMdSPortals)
            window.close()
          }
          window.location = "/"
        }      
      }
}
  };
  const postToCMDSPortals = (c) => {
    let lwn = getLWN();
    for (let i = 0; i < lwn.length; i++) {
      let x = window.open("", lwn[i]);
      x?.postMessage(c, process.env.REACT_APP_CLAIMS_URL);
      x?.postMessage(c, process.env.REACT_APP_FIN_URL);
      x?.postMessage(c, process.env.REACT_APP_WP_URL);
    }
  };
  const checkTabClose = (s, n) => {
    setTimeout(() => {
      if (s.closed) {
        let lwn = getLWN();
        if (lwn.indexOf(n) > -1) {
          lwn.splice(lwn.indexOf(n), 1);
          localStorage.setItem("localWindowName", JSON.stringify(lwn));
        }
      }
    }, 100);
  };
  React.useEffect(() => {
    if(window.name != CMdSPortals ){
      window.addEventListener('beforeunload', e => {
      if(localStorage.getItem('username')){
        let lwn = getLWN();
        if (lwn.indexOf(window.name) > -1) {
          lwn.splice(lwn.indexOf(window.name), 1);
          localStorage.setItem("localWindowName", JSON.stringify(lwn));
        }      
      }
      window.onbeforeunload = null;
      window.close()
      e.stopImmediatePropagation();
    })
    window.addEventListener("storage", (event) => {
       if (event.key === "isLogOutClean"){
        if (localStorage.getItem("isLogOutClean") === "true") {
          window.close()
        }
      }
    });
  }
else {
  window.addEventListener("message", (e) => {
    if (
      e.origin === process.env.REACT_APP_CLAIMS_URL ||
      e.origin === process.env.REACT_APP_FIN_URL ||
      e.origin === process.env.REACT_APP_WP_URL
    ) {
      if (e.data === "logOut") {
        logOut();
      }
      if (e?.data && typeof(e.data)==="string" && e.data.includes('checkTabClose')) {    
        if(e.data.split('-')[1]){
          checkTabClose(e.source, e.data.split('-')[1]);
        }               
      }
    }
  });
  window.addEventListener("storage", (event) => {
    if (event.key === "username") {
      if (isLogin && !localStorage.getItem("username")) {
        logOutClean();
      }
    }  else if (event.key === "initLogin") {
      if (localStorage.getItem("initLogin") === "true") {
        window.location = "/";
      }
    } else if (event.key === "isLogOutClean"){
      if (localStorage.getItem("isLogOutClean") === "true") {
        window.location = "/";
      }
    }
  });
}
  });

  React.useEffect(() => {
    let diff = moment(successData.passwordExpiry).diff(moment(new Date()),'days');
    // Normal Login which is > 7 for password expiry
    if(diff > process.env.REACT_APP_PASSWORD_EXPIRY_DAYS && loginState && !successData.forcePasswordChange){
      setIsLogin(true);
      setspinnerLoader(false);      
      setWarningMessages([]);
      setErrorMessages([]);
      setInfoMessages([]);
      setSuccessMessages([]);
    }
    else if(diff <= process.env.REACT_APP_PASSWORD_EXPIRY_DAYS && loginState && !successData.forcePasswordChange){
    // Normal Login which is < 7 for password expiry
      setspinnerLoader(false);
      setIsLogin(true);      
      setErrorMessages([]);
      setInfoMessages([]);
      setSuccessMessages([]);
      setWarningMessages([ "Your Password is about to expire in " +
      `${diff}`+
      " days.",])
    }else if(successData.forcePasswordChange && loginState) {
      setLoginType('')
      setIsPassword(true);
      setspinnerLoader(false);
      setIsLogin(false);
      setErrorMessages([]);
      setInfoMessages([]);
      setSuccessMessages([]);
      setWarningMessages([ErrorMsgConstants.FIRST_TIME_LOGIN]);
    }
  },[loginState]);

  const networkErrorHandler = () => {
    setspinnerLoader(false);
    setErrorMessages([ERR_PROCESSING_REQ]);
    setTimeout(() => setErrorMessages([]), 5000);
  };

  axios.interceptors.request.use(
    async function (config) {
      config.headers.Username = localStorage.getItem('username');
      config.headers.Authorization = localStorage.getItem('accessToken');
      config.headers["X-CMDS-APP-CODE"] = X_CMDS_APP_CODE;
      return config;
    },
    function () {
      networkErrorHandler();
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function () {
      networkErrorHandler();  
    }
  );

  React.useEffect(() => {
    if (isLogin) {
      localStorage.setItem("isLogOutClean","false")
    }
  }, [isLogin]);
  React.useEffect(() => {
    if (uid) {
      setspinnerLoader(true);
      ssoLogin();
    } else if (code) {
      setspinnerLoader(true);
      localStorage.setItem("code", code);
      window.location = "/";
    } else if (localStorage.getItem("username")) {
      getAppInfo();
    } else if (scode) {
      setspinnerLoader(true);
      getAccessToken(scode);
    }
    document.title = CMdSPortals;
  }, []);
  const getAccessToken = (c) => {
    var data = qs.stringify({
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      code: c,
      redirect_uri: window.location.href,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
    var config = {
      method: "post",
      url: process.env.REACT_APP_ACCESS_TOKEN,
      headers: {
        Authorization: process.env.REACT_APP_AUTHORIZATION_CODE,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
  const acccessTokenAxios = axios.create();
  acccessTokenAxios(config)
      .then(function (response) {        
        getAppInfo(response);
      })
      .catch(function (error) {
        logOut();
      });
  };
  const getAppInfo = (r) => {
    let d;
    let data;
    if (r) {
      d = parseJwt(r.data.id_token);
      data = {
        username: d.sub,
        accessToken: r.data.access_token,
        refreshToken: r.data.refresh_token,
      };
    }
    else{
      data = {
        username: localStorage.getItem("username"),
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
      };
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_SECURITY_HOST}security/auth/tokenappinfo`,
      data: data,
    };
    axios(config)
      .then(function (r) {
        const d = parseJwt(r.data.data);
        if(process.env.REACT_APP_LOGIN_THROUGH === TextConst.PRODUCT && d?.forcePasswordChange){          
          setIsLogin(false);
          dispatch(setForcePasswordChange(true));       
        }else{
          setIsLogin(true);
        }        
        setUser(`${d.lastname}, ${d.firstname}`);
        localStorage.removeItem('code'); 
        localStorage.setItem("user", `${d.lastname}, ${d.firstname}`);
        localStorage.setItem("initLogin", "true");
        sessionStorage.setItem("accessToken", r.data.accessToken);
        safeLocalStorage(
          'set',
          'loginState',
          JSON.stringify({
            loginUserName: d?.username,
            loginUserId: d?.userId,
            userPrivileges: d?.roles?.split(','),
            time: Date.now(),
            applist: d?.applist,
            passwordExpiry: d?.passwordExpiry,
            firstName: d?.firstname,
            lastName: d?.lastname,
            forcePasswordChange: d?.forcePasswordChange,
            locked: d?.locked,
            email:d?.email,
          })              
        );
        setSuccessData(d);
        setLoginState(true);
        storeCred(
          r.data.accessToken,
          r.data.refreshToken,
          d.username,
          r.data.expiresInSeconds,
          r.data.ssoToken
        );
        storeAppInfo(d?.applist || []);
        setAppList(d?.applist || []);        
        setspinnerLoader(false);               
      })
      .catch(function (error) {
        logOut();
      });
  };
  const refreshToken = (rToken, userName) => {
    axios
      .get(
        `${process.env.REACT_APP_SECURITY_HOST}security/token/refresh/${userName}/${rToken}`
      )
      .then((r) => {
        const d = parseJwt(r.data.data);
        storeCred(
          r.data.accessToken,
          r.data.refreshToken,
          d.username,
          r.data.expiresInSeconds,
          r.data.ssoToken,
          true
        );
      })
      .catch((e) => {
        logOut();
      });
  };
  const ssoLogin = () => {
    window.location.replace(process.env.REACT_APP_SSO_LOGIN);
  };
  const openTab = (url, target, closeTabOnLogout = false) => {
    if (downtime) {
      return false;
    }
    const wn = new Date().getTime().toString(); 
    const openedWindow = window.open(url, wn,); 
 
    if (!closeTabOnLogout && openedWindow) {
        let lwn = getLWN(); 
        lwn.push(wn);
        localStorage.setItem("localWindowName", JSON.stringify(lwn));
    }
 };

  const getUrl = (h) => {
    return h == USER_SEARCH ? `${h}?isClicked=${localStorage.getItem('accessToken')?'yes':null}&user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}&show=HOME`//Home
    : h == process.env.REACT_APP_WP_URL ? 
    `${h}?user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}&impStateInd=${localStorage.getItem('impStateInd')}`
    : `${h}?user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
  };
  const openManageAccont = ()=>{
    localStorage.setItem("Checked","yes")
      openTab(getUrl(USER_SEARCH), '_blank')
  }
  const handleAppOnClick = (app) => {
    if (app.id === "MANAGEACCOUNT" || app.id === "MANAGEACCOUNTR") {
      openManageAccont();
    } else {
      const url = process.env[app.urlKey];
      app.useDirectUrl ? openTab(url, '_blank', app.useDirectUrl) : openTab(getUrl(url), '_blank');
    }
  };  
  const logOut = () => {
    securityLogout();
  };
  const resetState = () => {
    setspinnerLoader(false);
    if(localStorage.getItem("logInType") !== 'NORMAL_LOGIN' && process.env.REACT_APP_LOGIN_THROUGH !== TextConst.PRODUCT && process.env.REACT_APP_LOGOUT_REDIRECT){
      window.location = process.env.REACT_APP_LOGOUT_REDIRECT
    }else{
      window.location = '/';
    }
  };
  const securityLogout = () => {
    setspinnerLoader(true);
    axios
      .post(`${process.env.REACT_APP_SECURITY_HOST}security/auth/logout`, {
        username: localStorage.getItem("username"),
        accessToken: localStorage.getItem("accessToken"),
        ssoToken: localStorage.getItem("ssoToken"),
      })
      .then((r) => {
        if (sessionStorage.getItem("accessToken")) {
          samlLogout();
        } else {
          logOutClean();
          resetState();
        }
      })
      .catch((e) => {
        setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION]);
        setspinnerLoader(false);
      });
  };
  const logOutClean = () => {
    deleteAllCookies();
    postToCMDSPortals("logOut");
    closeWindows();
    clearInterval(tokenIntervalID);
    window.localStorage.clear();
    window.sessionStorage.clear();
    localStorage.setItem("initLogin", "false");
    localStorage.setItem("Checked","no")
    localStorage.setItem("isLogOutClean","true")
  };
  const samlLogout = () => {
    setspinnerLoader(true);
    let element = document.createElement("iframe");
    element.setAttribute("id", "samlframe");
    document.body.appendChild(element);
    element.style.display = "none";
    element.src = process.env.REACT_APP_SSO_LOGOUT;
    setTimeout(()=>{
      logOutClean();
      resetState();
    },1000);
  };
  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
    }
  };  
  const handleLink = (linkName) => {
    if (linkName == "PrivacyPolicy") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "TermsofUse") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "BrowserRequirements") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "AccessibilityCompliance") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "SiteMap") {
      history1.push("/SiteMap");
    }
  };

  // My Account Functionality
  const [isMyAccount,setIsMyAccount] = React.useState(false);
  
  const navigateToMyAccount =()=>{      
    callingGetDetails()
    setIsMyAccount(true);
  }
  const getMyAccountUrl = (h) => {
 
    return h==MY_ACCOUNT?`${h}?isClicked=${isMyAccount?'yes':null}&user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}&show=HOME`
    :
    `${h}?user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}}`;
  };
  const callingGetDetails = () => {
    let loginState = JSON.parse(localStorage.getItem('loginState'));
    getDetails(loginState?.loginUserId)
  }
  React.useEffect(() => {
  if (userDetailsData && userDetailsData?.statusCode === '01' && isMyAccount) {
    setErrorMessages([])
    setspinnerLoader(false)
    localStorage.setItem("Checked","yes")
    localStorage.setItem("userDetails", JSON.stringify(userDetailsData?.responseObject));
    openTab(getMyAccountUrl(MY_ACCOUNT),'_blank');    
    setIsMyAccount(false);
  } else if (userDetailsData && userDetailsData?.statusCode !== '01' && userDetailsData?.statusDescription !== "Success" && isMyAccount) {
    setspinnerLoader(false)
    setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION])
  }
  }, [userDetailsData, isMyAccount]);

  return (
  <> 
        {spinnerLoader ? <Spinner /> : null}
{footerLinks ? <FooterLinks footerLinksName ={footerLinksName} /> 
 :
  <main className="before-login ">
      <Header isLogin = {isLogin} setIsLogin ={setIsLogin} showLink={window.name != CMdSPortals?['Home']:showLink} navigateToMyAccount={navigateToMyAccount} checked={checked} logOut={logOut}/>   
  <div className={`normallogin-container ${checked && window.name != CMdSPortals && "main-body"}`}>
    {isLogin && (downtime || upComingDowntime) ? (
      <div className={`alert alert-${downtime && 'danger' || 'warning'} sso-page-alerts`}>
        <ul className="m-0">
          {<li>{downtime ? donwTimeMsg.replace('will be', 'is') : donwTimeMsg}</li>}
        </ul>
      </div>
    ) : null}
     {warningMessages.length > 0 ? (
        <div className="alert alert-warning custom-alert" role="alert">
          {warningMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
     {infoMessages.length > 0 ? (
        <div className="alert alert-info custom-alert" role="alert">
          {infoMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {successMessages.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {errorMessages.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
    <div className="container-fluid h-100">
    {
       checked == 'yes' &&  localStorage.getItem('username') && localStorage.getItem('accessToken') && window.name != CMdSPortals && window.location.pathname != '/'
      ?
      <ManageUser accessToken={localStorage.getItem('accessToken')} username={localStorage.getItem('username')}/>
      :
      <div className="row h-100 white-bg">
        <section className="col-lg-8 col-md-6 col-sm-12 banner-panel">
          <div className="log-courosal">
            <div className="log-banner">
              <img
                title="Login Banner"
                src="./images/banner.png"
                alt="login image"
              />
            </div>
          </div>
          <div className="row h-100 position-relative">
            <div className="col-md-8 col-xs-12 text-center">
              <div className="carousel-content py-2">
                <div className="carousel-text">
                  <h4> Welcome to</h4>
                  <h1>{window.name= CMdSPortals}</h1>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 px-0">
              <div className="log-action-center">
                <div className="log-notifications">
                  <h3 className="notify-heading purple-brd"> Updates</h3>
                  <div className="clear-block">
                    <div className="notify-link">Latest Policy News</div>
                  </div>
                </div>

                <div className="log-notifications">
                  <h3 className="notify-heading"> Quick Links</h3>
                  <div className="clear-block">
                    <div className="notify-link">FAQ</div>
                    <div className="notify-link">System Integration</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col-lg-4 col-md-6 col-sm-12 portal-panel">
          <div className="portal-wrapper">
            {isLogin ? (
              <>
                <div className="set-logOut">
                  <div className="welcome-user">
                    <h1>Hello {user ? user : userData?.lastName && userData?.firstName ? userData.lastName + ' ' + userData?.firstName : ''}!</h1>
                    <p className="heading-text">
                      Select the suitable portal of your choice
                    </p>
                  </div>
                  <div className="portal-wrapper-inner scrollable-container">
                    {appList.length > 0 && appList.map((app) => {
                        return (
                          <div className={`portal-menu ${downtime && "error-state"}`} key={app.id}>
                            <div
                              title={app.title}
                              className="portal-menu-item"
                              onClick={() => handleAppOnClick(app)}
                            >
                              <span className={`portal-menu-icon ${app.iconClass}`}>&nbsp;</span>
                              <div className="portal-tab-txt">
                                <p className="portal-menu-title">{app.title}</p>
                                <span className="portal-menu-desc">{app.description}</span>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                  </div>
                </div>
                <div className="user-logout text-center">
                <div className="logOut-btn mr-3" onClick={navigateToMyAccount}>
                    <span className="my-account"></span>
                    My Account
                  </div>
                   <div className="logOut-btn" onClick={logOut}>
                    <span className="fa-solid fa-right-from-bracket"></span>
                    {(localStorage.getItem("logInType") !== 'NORMAL_LOGIN' && process.env.REACT_APP_LOGOUT) || 'Logout'}
                  </div>
                </div>
              </>
            ) :
              <>
              {logInType === TextConst.NORMAL_LOGIN ?
                <LoginPage 
                  setWarningMessages ={setWarningMessages} 
                  setErrorMessages={setErrorMessages} 
                  logOut={logOut} 
                  logOutClean={logOutClean}
                  setInfoMessages = {setInfoMessages} 
                  infoMessages={infoMessages} 
                  setspinnerLoader={setspinnerLoader}
                  setAppList={setAppList} 
                  setUser={setUser}
                  appList={appList} 
                  storeAppInfo={storeAppInfo} 
                  setIsLogin={setIsLogin} 
                  isLogin={isLogin} 
                  setSuccessMessages={setSuccessMessages} 
                  storeCred={storeCred}
                  setLoginType={setLoginType}
                  setIsPassword={setIsPassword}
                  setLoginState={setLoginState}
                  loginState={loginState}
                  values={values}
                  setValues={setValues}
                  submitSuccess={submitSuccess}
                  errors={{invalidUsrPassErr}}
                />
              : isPassword ?
                <ChangePassword 
                  setIsPassword = {setIsPassword} 
                  setIsLogin ={setIsLogin}                   
                  logOut={logOut}
                  setInfoMessages={setInfoMessages} 
                  setWarningMessages={setWarningMessages} 
                  setErrorMessages = {setErrorMessages} 
                  setAppList ={setAppList}
                  setSuccessMessages={setSuccessMessages} 
                  setspinnerLoader={setspinnerLoader} 
                  setValues={setValues} 
                  values={values}
                  submitSuccess={submitSuccess} 
                  setChangeState={setChangeState} 
                  setformValue={setformValue} 
                  formValue={formValue} 
                  defaultFormValue={defaultFormValue}
                  setLoginType={setLoginType}
                  setLoginState={setLoginState}
                  redirectToLandingPage={redirectToLandingPage}
                />
              :
                <NewLogin setLoginType={setLoginType}/>
            }</>
            }                
          </div>
        </section>
      </div>
      }
    </div>
  </div>
</main>
}

<footer className="mt-0">       
            <div className="row m-0 mb-2 align-center">
              <div className="col-md-6">
                <p className="footer-text my-2">
                  ©2019 Conduent, Inc. All rights reserved. Conduent and
                  Conduent Agile Star are trademarks of Conduent, Inc.
                  and/or its subsidiaries in the United States and/or other
                  countries
                </p>
                <div align="center" className="mt-2">
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("PrivacyPolicy");
                    }}
                    title="Privacy Policy"
                  >
                    Privacy Policy
                  </a>
                </span>                
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("TermsofUse");
                    }}
                    title="Terms of Use"
                  >
                   Terms of Use
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("BrowserRequirements");
                    }}
                    title="Browser Requirements"
                  >
                    Browser Requirements
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("AccessibilityCompliance");
                    }}
                    title="Accessibility Compliance"
                    className="lst-child"
                  >
                    Accessibility Compliance
                  </a>
                </span>
              </div>
              </div>
            </div>
      </footer>

</>
    
  );
};

export default HomePage;
