import * as actionTypes from './ActionTypes';
import * as commonServiceEndPoint from '../../services/service';
import { safeLocalStorage } from '../../../SharedModules/utility/utilityFunction';
import axios from 'axios';
import Cookies from 'js-cookie';

let tokenIntervalID = null, inactivityTimeOutID = null, useDispatch = null;
export const getCurrentPath = (currentPath) => {

  return {
    type: actionTypes.GET_CURRENT_PATH,
    currentPath: currentPath
  };
};


export const getUserDetails = (value) => {
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.GET_USER_PRIVILEGES}?userId=${value}`)
      .then(response => {
        if (response) {
          console.log(response);
        }
        dispatch(setUserprivileges(response.data));
      })
      .catch(error => {
        dispatch(setUserprivileges([]));
        console.log(error);
      });
  };
};
export const startUsrInactivityTimeOut = () => {   
  inactivityTimeOutID && clearTimeout(inactivityTimeOutID);
  inactivityTimeOutID = setTimeout(() => {    
    useDispatch(dispatchSessionExpiry(true));
  },eval(5*1000)); // need to add in env file
  
};
export const stoptUsrInactivityTimeOut = () => {     
  clearTimeout(inactivityTimeOutID);   
};
export const dispatchSessionExpiry = (status) => {
  return {
    type: actionTypes.SHOW_SESSION_EXPIRY_DIALOG,
    status: status   
  };
};
export const dispatchSessionExpand = (status) => {
  return {
    type: actionTypes.SESSION_EXPANDED,
    status: status   
  };
};
export const multiDropDownGetValues = (data) => {
  return {
    type: actionTypes.MULTI_DROPDOWN_VALUES_ACTION,
    multiDropDownData: data
  };
};
export const updateUserInOutAction = (status) => {
  return {
    type: actionTypes.USER_IN_OUT_ACTION,
    status: status   
  };
};
const userInEventHandler = () => {
  useDispatch(updateUserInOutAction("IN"));  
};
const userOutEventHandler = () => {
  useDispatch(updateUserInOutAction("OUT"));
};
export const checkUserActivity = () => {
  return dispatch => { 
    useDispatch =  dispatch;
    window.addEventListener('blur', userOutEventHandler);
    window.addEventListener('focus', userInEventHandler);  
  };
};
export const verifyAddressAPI = (value) => {
  return dispatch => {
    return axios.post(`${commonServiceEndPoint.ADDRESS_VERIFY}`, value)
      .then(response => {
        if (response.data === undefined) {
          dispatch(setValidAddress([]));
        } else {
          dispatch(setValidAddress(response.data));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(setValidAddress({ systemFailue: true }));
      });
  };
};
export const userLogout = (isReload,usr=safeLocalStorage('get', 'loginState')) => {  
  return dispatch => {
    return axios.post(`${commonServiceEndPoint.TOKEN_LOGOUT}`, {
      "username": usr ? JSON.parse(usr).loginUserName : null,
      "accessToken": localStorage.getItem('accessToken'),
      "ssoToken": localStorage.getItem('ssoToken'),
      })
      .then(response => {
        if (response && response.data) {
          dispatch(logOut(isReload));
        }
      })
      .catch(error => {
        dispatch(logOut(isReload));
      });
  };
};
export const logOut = (isReload, isSSOLogin) => {
  return dispatch => {    
    const lsso = localStorage.getItem('lsso');
    dispatch(setUserprivileges([]));
    clearInterval(tokenIntervalID);            
    window.removeEventListener('blur', userOutEventHandler);
    window.removeEventListener('focus', userInEventHandler);
    localStorage.clear();
    Cookies.remove('userProfile');
    document.cookie = `iPlanetDirectoryPro=; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN};Secure; expires=` + new Date(0).toUTCString();
    if(lsso){
      if(window.opener){
        window.opener.postMessage('logOut', window.opener.origin);
      }
      window.location.replace(process.env.REACT_APP_LANDING_SCREEN_URL);
    }
    else if(isReload && !isSSOLogin){
      window.onbeforeunload=null;
      window.location = '/';
    }    
  };
};
export const UserLogout = (uid) => {
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.USER_LOGOUT}?uid=${uid}`
    )
      .then(response => {
        if (response && response.data) {
          console.log(response.data);
        }
        dispatch(setUserprivileges([]));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const setValidAddress = (data) => {
  return {
    type: actionTypes.ADDRESS_VERIFY,
    addressValidatedData: data
  };
};
export const ssoLogin = (data) => {
  return {
    type: actionTypes.SSO_LOGIN,
    ssoLogin: data
  };
};
export const setUserprivileges = (data) => {
  return {
    type: actionTypes.USER_LOGGIN_ACTION,
    userPrivileges: data
  };
};
export const getUserInquiryPrivilege = (data) => {
  return {
    type: actionTypes.USER_INQUIRY_PRIVILEGE,
    userInquiryPrivileges: data
  };
};
export const setLogInUserID = (data) => {
  return {
    type: actionTypes.LOGIN_USER_ID,
    logInUserID: data ? data.toString().toUpperCase() : data
  };
};

export const setPrintLayout = (data) => {
  return {
    type: actionTypes.SET_PRINT_LAYOUT,
    printLayout: data
  };
};

// export const downloadFileAction = (folder, fileName) => {
//   debugger
//   return dispatch => {
//     return axios.post(`${commonServiceEndPoint.DOWNLOAD_ACTION_LINK}/${folder}/${fileName}`)
//       .then(response => {
//         debugger
//         if (response.data === undefined) {
//           dispatch(setDownloadFile({ systemFailue: true }));
//         } else {
//           var myURL = window.URL || window.webkitURL;
//           const objectUrl = myURL.createObjectURL(new Blob([response.data]));
//           dispatch(setDownloadFile(objectUrl));
//         }
//       })
//       .catch(error => {
//         console.log(error);
//         dispatch(setDownloadFile({ systemFailue: true }));
//       });
//   };
// };

export const downloadFileAction = (values) => {
  return dispatch => {
    return axios.post(`${commonServiceEndPoint.DOWNLOAD_ACTION_LINK}`, values, { responseType: "blob" })
      .then(response => {
        if (response.data === undefined) {
          dispatch(setDownloadFile({ systemFailue: true }));
        } else {
          var myURL = window.URL || window.webkitURL;
          const objectUrl = myURL.createObjectURL(new Blob([response.data]));
          dispatch(setDownloadFile(objectUrl));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(setDownloadFile({ systemFailue: true }));
      });
  };
};
// export const refreshAttachmentsAction = (fcn) => {
//   return dispatch => {
//     return axios.get(`${commonServiceEndPoint.REFRESH_ATTACHMENTS_LINK}?fcn=${fcn}`)
//       .then(response => {
//         if (response.data === undefined) {
//           dispatch(setAttachmnetsAfterRefresh([]));
//         } else {
//           dispatch(setAttachmnetsAfterRefresh(response.data));
//         }
//       })
//       .catch(error => {
//         console.log(error);
//         dispatch(setAttachmnetsAfterRefresh({ systemFailue: true }));
//       });
//   };
// };
export const setAttachmnetsAfterRefresh = (data) => {
  return {
    type: actionTypes.REFRESH_ATTACHMENTS,
    newAttachmentResult: data
  };
};
export const setDownloadFile = (data) => {
  return {
    type: actionTypes.DOWNLOAD_FILE_ACTION,
    downloadeFile: data
  };
};
export const setAttachmentResult = (data) => {
  return {
    type: actionTypes.ATTACHMENT_API,
    attachmentResult: data
  };
};