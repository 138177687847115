import * as actionTypes from './actionTypes';

const axiosPayLoad = {
  userByNameSearchData: null,
  searchByNameFields: null,  
  searchByIdData: null,
  searchByIdFieldValue:null,
  validateUserResponse: null,
  validateUserTime: new Date, 
  type: 'Add', 
  userDetailsResponse: null,
  myAccDetailsResponse: null,
  saveUserDetailsResponse: null,
  resetPasswordResponse: null,
  resetPasswordTime: new Date,
  updateUserResponse: null,
  updateUserTime: new Date,
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_USER:
      return { ...state, userByNameSearchData: action.searchData,searchByNameFields:action.fieldsValue, inquirySearchTime: new Date()}
    case actionTypes.RESET_USER:
      return {...state, userByNameSearchData:null};
    case actionTypes.RESET_ALL_STATE:
      return axiosPayLoad;
    case actionTypes.RESET_USER_BY_ID:
      return {...state, searchByIdData:null};
    case actionTypes.SEARCH_USER_BY_ID:
      return { ...state, searchByIdData: action.searchData,searchByIdFieldValue:action.fieldsValue, inquirySearchTime: new Date()}
    case actionTypes.VALIDATE_USER:
      return {...state, validateUserResponse: action.validateUserResponse, validateUserTime: new Date };
    case actionTypes.GET_USER_DETAILS:
      return {...state, userDetailsResponse: action.userDetailsResponse, type: 'Edit' };
    case actionTypes.GET_MY_ACCOUNT_DATA:
      return {...state, myAccDetailsResponse: action.myAccDetailsResponse};
    case actionTypes.RESET_USER_DETAILS:
      return {...state, userDetailsResponse: null, type: 'Add', saveUserDetailsResponse: null, validateUserResponse: null, resetPasswordResponse: null, updateUserResponse: null};
    case actionTypes.SAVE_USER_DETAILS:
      return {...state, saveUserDetailsResponse: action.saveUserDetailsResponse };
    case actionTypes.USER_RESET_PASSWORD:
      return {...state, resetPasswordResponse: action.resetPasswordResponse, resetPasswordTime: new Date };
    case actionTypes.UPDATE_USER_DETAILS:
      return {...state, updateUserResponse: action.updateUserResponse, updateUserTime: new Date };
    case actionTypes.DISPATCH_USER_DATA:
      return {...state, userDetailsResponse: action.userDataResponse, type: 'Edit' };
    case actionTypes.FORCE_PASSWORD_CHANGE:
      return {...state, forcePasswordChange: action.forcePasswordChange };
    default: return state;
  }
};

export default reducer;
