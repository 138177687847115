import axios from 'axios';
import * as actionTypes from './actionTypes';
import * as serviceEndPoint from '../../SharedModules/services/service';

export const dispatchValidateUser = response => ({
    type:actionTypes.VALIDATE_USER,
    validateUserResponse : response
});
  
export const validateUserAction = (userId) => async dispatch => {
    return await axios.get(serviceEndPoint.MANAGE_ACCOUNT_VALIDATE_USER + userId)
        .then(response => {
            if (response.status == "200") {
                dispatch(dispatchValidateUser(response.data));
            } else {
                dispatch(dispatchValidateUser(response.data.message));
            }
        })
        .catch(error => {
            dispatch(dispatchValidateUser(error));
        })
};

export const dispatchUserDetails = response => ({
    type:actionTypes.GET_USER_DETAILS,
    userDetailsResponse : response
});
  
export const getUserDetailsAction = (userId) => async dispatch => {
    return await axios.get(serviceEndPoint.MANAGE_ACCOUNT_USER_DETAILS + userId)
        .then(response => {
            if (response.status == "200") {
                dispatch(dispatchUserDetails(response.data));
            } else {
                dispatch(dispatchUserDetails(response.data.message));
            }
        })
        .catch(error => {
            dispatch(dispatchUserDetails(error));
        })
};

export const dispatchResetUserDetails = () => ({
    type:actionTypes.RESET_USER_DETAILS
});

export const dispatchSaveUserDetails = response => ({
    type:actionTypes.SAVE_USER_DETAILS,
    saveUserDetailsResponse : response
});
  
export const saveUserDetailsAction = (data) => async dispatch => {
    return await axios.post(serviceEndPoint.MANAGE_ACCOUNT_SAVE_DETAILS, data)
        .then(response => {
            if (response.status == "200") {
                dispatch(dispatchSaveUserDetails(response.data));
                if (response.data && response.data.statusCode == "01") {
                    dispatch(dispatchUserData(response.data));
                }
            } else {
                dispatch(dispatchSaveUserDetails(response.data.message));
            }
        })
        .catch(error => {
            dispatch(dispatchSaveUserDetails(error));
        })
};

export const dispatchResetPassword = response => ({
    type:actionTypes.USER_RESET_PASSWORD,
    resetPasswordResponse : response
});
  
export const resetPasswordAction = (userId, revId) => async dispatch => {
    return await axios.get(serviceEndPoint.MANAGE_ACCOUNT_RESET_PASSWORD_DETAILS + userId + '/' + revId)
        .then(response => {
            if (response.status == "200") {
                dispatch(dispatchResetPassword(response.data));
            } else {
                dispatch(dispatchResetPassword(response.data.message));
            }
        })
        .catch(error => {
            dispatch(dispatchResetPassword(error));
        })
};

export const dispatchUpdateUser = response => ({
    type:actionTypes.UPDATE_USER_DETAILS,
    updateUserResponse : response
});
  
export const updateUserAction = (data) => async dispatch => {
    return await axios.post(serviceEndPoint.MANAGE_ACCOUNT_UPDATE_DETAILS, data)
        .then(response => {
            if (response.status == "200") {
                dispatch(dispatchUpdateUser(response.data));
                if (response.data && response.data.statusCode == "01") {
                    dispatch(dispatchUserData(response.data));
                }
            } else {
                dispatch(dispatchUpdateUser(response.data.message));
            }
        })
        .catch(error => {
            dispatch(dispatchUpdateUser(error));
        })
};

export const dispatchUserData = response => ({
    type:actionTypes.DISPATCH_USER_DATA,
    userDataResponse : response
})


export const dispatchMyAccountDetails = response => ({
    type:actionTypes.GET_MY_ACCOUNT_DATA,
    myAccDetailsResponse : response
});
  
export const getMyAccDetailsAction = (userId) => async dispatch => {
    return await axios.get(serviceEndPoint.MANAGE_ACCOUNT_USER_DETAILS + userId)
        .then(response => {
            if (response.status == "200") {
                dispatch(dispatchMyAccountDetails(response.data));
            } else {
                dispatch(dispatchMyAccountDetails(response.data.message));
            }
        })
        .catch(error => {
            dispatch(dispatchMyAccountDetails(error));
        })
};

export const setForcePasswordChange = data => ({
    type: actionTypes.FORCE_PASSWORD_CHANGE,
    forcePasswordChange : data
});