import React from "react";
import "../../theme.scss";
import axios from "axios";
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import * as serviceEndPoint from '../../SharedModules/services/service';
import ChangePassword from './ChangePassword'; 
import * as ErrorMsgConstants from '../../SharedModules/Messages/ErrorMsgConstants';
import {
  parseJwt, safeLocalStorage
} from '../../SharedModules/utility/utilityFunction';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  }));

function LoginPage(props) {
  const { storeCred } = props;
  const classes = useStyles();
  const [isPassword, setIsPassword] = React.useState(false);
  const [successData, setSuccessData] = React.useState('');
  const [loginState, setLoginState] = React.useState(false);
  const [changeState, setChangeState] = React.useState(false);
  const defaultFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const [formValue, setformValue] = React.useState(defaultFormValue);
  const [
    {
      userNameReqErr,
      userNameMinLenErr,
      userNameInvalidErr,
      invalidPasswordErr,
      passwordReqErr,
      voiChk,
      userLockedErr
    },
    setShowErrors,
  ] = React.useState(false);
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
    count: 0,
    void: false,
    accessBox: false,
    linksBox: true,
    updatesBox: true,
  });

  const inputRef = React.useRef(null);

  const handleChanges = value => {
    values.void = value;
  };
  const handleChange = prop => event => {
    if (prop == 'username') {
      const { selectionStart, selectionEnd } = event.target;
      setValues({ ...values, [prop]: event.target.value?.toUpperCase() });
      setTimeout(() => {
        inputRef?.current?.setSelectionRange(selectionStart, selectionEnd);
    }, 0);
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const submitSuccess = () => { 
    props.setInfoMessages([]);
    props.setWarningMessages([]);
    props.setSuccessMessages([]);
    props.setErrorMessages([]);
    props.setspinnerLoader(true);
      const data = {
        "password": changeState ? formValue.newPassword : values.password,
        "username": values.username.toUpperCase()
      };
      axios.post(`${serviceEndPoint.USER_LOGIN_ENDPOINT}`, data)
        .then(res => {
          if (res.data.success === true) {  
            props.setspinnerLoader(false);  
            let d = parseJwt(res.data?.data);
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('username', d?.username);
            localStorage.setItem("user", `${d.lastname}, ${d.firstname}`);
            setSuccessData(d);           
            storeAppInfo(d?.applist || []);
            props.setAppList(d?.applist || []);            
            setLoginState(true)
            safeLocalStorage(
              'set',
              'loginState',
              JSON.stringify({
                loginUserName: d?.username,
                loginUserId: d?.userId,
                userPrivileges: d?.roles.split(','),
                time: Date.now(),
                applist: d?.applist,
                passwordExpiry: d?.passwordExpiry,
                firstName: d?.firstname,
                lastName: d?.lastname,
                forcePasswordChange: d?.forcePasswordChange,
                locked: d?.locked,
                email:d?.email,
                accessToken:d?.accessToken,
                refreshToken:d?.refreshToken
              })              
            ); 
            storeCred( res.data?.accessToken,
              res.data?.refreshToken,
              d?.username,
              res.data?.expiresInSeconds,
              res.data?.ssoToken);
          } else {
            props.setspinnerLoader(false);
            window.localStorage.clear();
            window.sessionStorage.clear();
            if(res.data.success === false && 
              (res.data?.errorMessage === ErrorMsgConstants.LOGIN_FAILURE 
                || res.data?.errorMessage === ErrorMsgConstants.AFTER_ONE_MORE_ATTEMPT 
                || res.data?.errorMessage === ErrorMsgConstants.AFTER_TWO_MORE_ATTEMPT 
                || res.data?.errorMessage === ErrorMsgConstants.NO_SPECIAL_CHAR_UN_PWD 
                || res.data?.errorMessage === ErrorMsgConstants.AUTHENTICATION_FAILED 
                || res.data?.errorMessage === ErrorMsgConstants.CREDENTIAL_NVALID)){
              setShowErrors({
                invalidPasswordErr : true
              });
              props.setInfoMessages([]);
              props.setWarningMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.INVALID_CREDENTIALS]);
            }
            if(res.data?.success === false &&
              res.data?.errorMessage === ErrorMsgConstants.ACC_INACTIVE){
              props.setInfoMessages([]);
              props.setWarningMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.ACCOUNT_DEACTIVATED]);
            }
            if(res.data?.success === false && (res.data?.errorMessage === ErrorMsgConstants.USER_LOCKED 
            || res.data?.errorMessage === ErrorMsgConstants.ACC_LOCKED
            || res.data?.errorMessage === ErrorMsgConstants.GRACE_LOGIN_EXHAUSTED)){
              props.setInfoMessages([]);
              props.setWarningMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.ACCOUNT_LOCKED]);
            }
            if(res.data?.success === false && res.data?.errorType === ErrorMsgConstants.PASSWORD_EXPIRED_ERR_TYPE 
            || res.data?.errorMessage === ErrorMsgConstants.ACCOUNT_PWD_EXP){
              setIsPassword(true);
              props.setInfoMessages([]);
              props.setWarningMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.PASSWORD_EXPIRED]);
            }
            if(res.data?.success === false && res.data?.errorType === ErrorMsgConstants.UNAUTHORIZED && res.data?.errorMessage === ErrorMsgConstants.ACC_INACTIVE){
              props.setInfoMessages([]);
              props.setWarningMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.DEACTIVATED]);
            }
          }
        })
        .catch(e => {
          props.setspinnerLoader(false);
          console.log("failure in catch")
          props.setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION])
        });
  }

  const storeAppInfo = (l = []) => {
    localStorage.setItem("applist", JSON.stringify(l));
  };

  React.useEffect(() => {
    props.setWarningMessages([]);
    props.setErrorMessages([]);
    props.setInfoMessages([]);
    props.setSuccessMessages([]);
    setShowErrors(false);
    setValues({
      username: '',
      password: '',
      showPassword: false,
      void: false,
    })
    let storedSession = JSON.parse(localStorage?.getItem("loginState"));
    if(storedSession !== null && !storedSession.forcePasswordChange){
      props.setAppList(storedSession?.applist || []);            
      props.setIsLogin(true);
      props.setspinnerLoader(false);
    }
  },[]);

  React.useEffect(() => { 
    let diff = moment(successData.passwordExpiry).diff(moment(new Date()),'days');
    // Normal Login which is > 7 for password expiry
    if(diff > process.env.REACT_APP_PASSWORD_EXPIRY_DAYS && loginState && successData.forcePasswordChange === false){
      props.setIsLogin(true);
      props.setspinnerLoader(false);
      props.setIsNormalLogin(false);
      props.setWarningMessages([]);
      props.setErrorMessages([]);
      props.setInfoMessages([]);
      props.setSuccessMessages([]);
    }
    else if(diff <= process.env.REACT_APP_PASSWORD_EXPIRY_DAYS && loginState && successData.forcePasswordChange === false){ 
    // Normal Login which is < 7 for password expiry
      props.setspinnerLoader(false);
      props.setIsLogin(true);
      props.setIsNormalLogin(false);
      props.setErrorMessages([]);
      props.setInfoMessages([]);
      props.setSuccessMessages([]);
      props.setWarningMessages([ "Your Password is about to expire in " +
      `${diff}`+
      " days.",])
    }else if(successData.forcePasswordChange === true && loginState) {
      setIsPassword(true);
      props.setIsNormalLogin(false);
      props.setspinnerLoader(false);
      props.setIsLogin(false);
      props.setErrorMessages([]);
      props.setInfoMessages([]);
      props.setSuccessMessages([]);
      props.setWarningMessages([ErrorMsgConstants.FIRST_TIME_LOGIN])
    }
  },[loginState]);

  axios.interceptors.request.use(
    async function (config) {
      const loginState = JSON.parse(localStorage.getItem('loginState'));
      let accessToken = localStorage.getItem('accessToken');
      config.headers.Username = loginState?.loginUserName
        ? loginState.loginUserName
        : null;
      config.headers.Authorization = accessToken;
      config.headers["X-CMDS-APP-CODE"] = 'CNDTAPPS';      
      config.timeout = 3 * 60 * 1000;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  const handleSubmit = () => {
    setShowErrors(false);
    values.username = values.username?.trim();
    if (values.username === '' && values.password === '') {
      setShowErrors({ userNameReqErr: true, passwordReqErr: true });
      return false;
    }
    if (values.username && values.password === '') {
      setShowErrors({ userNameReqErr: false, passwordReqErr: true });
      return false;
    }
    if (values.username === '' && values.password) {
      setShowErrors({ userNameReqErr: true, passwordReqErr: false });
      return false;
    }
    if (!values.void) {
      setShowErrors({ voiChk: true });
      return false;
    }
    submitSuccess()
    localStorage.setItem('isNormalUser', 'true');
  };

  return (
  <>    
        { isPassword ? (
                <ChangePassword setIsPassword = {setIsPassword} 
                setIsNormalLogin = {props.setIsNormalLogin} setIsLogin ={props.setIsLogin} setLoginState = {setLoginState} logOut={props.logOut}
                setInfoMessages={props.setInfoMessages} setWarningMessages={props.setWarningMessages} setErrorMessages = {props.setErrorMessages} setAppList ={props.setAppList}
                setSuccessMessages={props.setSuccessMessages} setspinnerLoader={props.setspinnerLoader} setValues={setValues} values={values}
                submitSuccess={submitSuccess} setChangeState={setChangeState} setformValue={setformValue} formValue={formValue} defaultFormValue={defaultFormValue}
                />
              )  
            : (
            <>
              <div className="ligin-form loginForm">
                <div className="form-wrapper">
                  <div className="wid-100 pb-2">
                    <TextField
                      data-test="user-name"
                      required
                      autoComplete='off'
                      id="UserName"
                      name="User ID"
                      label="User ID"
                      inputRef={inputRef}
                      value={values.username || ''}
                      onChange={handleChange('username')}
                      fullWidth
                      inputProps={{
                        minLength: 6,
                        maxLength: 30,
                        style:{textTransform:'uppercase'}
                      }}
                      helperText={
                        userNameReqErr
                          ? ErrorMsgConstants.USERNAME_REQ_ERR
                          : userNameMinLenErr
                          ? ErrorMsgConstants.USERNAME_MIN_LEN_ERR
                          : userNameInvalidErr
                          ? ErrorMsgConstants.USERNAME_EXIST_ERR
                          : null
                      }
                      error={
                        userNameReqErr
                          ? ErrorMsgConstants.USERNAME_REQ_ERR
                          : userNameMinLenErr
                          ? ErrorMsgConstants.USERNAME_MIN_LEN_ERR
                          : userNameInvalidErr
                          ? ErrorMsgConstants.USERNAME_EXIST_ERR
                          : null
                      }
                    />
                  </div>
                  <div className="wid-100 pb-1">
                    <FormControl
                      className={clsx(classes?.textField) + ' wid-100'}
                      required
                      error={passwordReqErr}
                    >
                      <InputLabel
                        htmlFor="standard-adornment-password"
                        className="Mui-required"
                      >
                        Password
                      </InputLabel>
                      <Input
                        data-test="user-name-password"
                        id="standard-adornment-password"
                        autoComplete="off"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        required
                        inputProps={{ maxLength: 15 }}
                      />
                      <FormHelperText required error={passwordReqErr}>
                        {passwordReqErr
                          ? ErrorMsgConstants.PASSWORD_REQ_ERR
                          : null}
                      </FormHelperText>
                    </FormControl>
                    {
                        userNameMinLenErr ? ErrorMsgConstants.USERNAME_MIN_LEN_ERR :
                                userNameInvalidErr ?<p
                                className="MuiFormHelperText-root Mui-error Mui-required"
                                role="alert"
                                > {ErrorMsgConstants.USERNAME_EXIST_ERR}</p>:
                                    userLockedErr ? <p
                                    className="MuiFormHelperText-root Mui-error Mui-required"
                                    role="alert"
                                    >{ErrorMsgConstants.USER_LOCKED_ERR_MESSAGE}</p> :         
                                    invalidPasswordErr ?  <p
                                    className="MuiFormHelperText-root Mui-error Mui-required"
                                    role="alert"
                                    > {ErrorMsgConstants.USERNAME_EXIST_ERR}</p> 
                                    : null
                    }                            
                  </div>
                  <div>
                    <a title="Forgot password" href="#" className="forgot-password">
                      Forgot Password? Please contact your system administrator.
                    </a>
                  </div>
                  <div className="input-md radio-100 mt-3 pb-1 t-and-c">
                    <div className="sub-radio set-rd-pos">
                      <Checkbox
                        type="checkbox"
                        value="void"
                        id="voidId"
                        color="primary"
                        onChange={e => handleChanges(e.target.checked)}
                      />
                      <label className="text-black-0 m-0 " htmlFor="voidId">
                        I accept all the terms &amp; conditions
                      </label>
                    </div>
                    {voiChk ? (
                      <p
                        style={{ fontStyle: '0.75rem' }}
                        className="MuiFormHelperText-root Mui-error Mui-required"
                      >
                        Please accept Terms and Conditions
                      </p>
                    ) : null}
                  </div>
                  <div className="w-100">
                    <Button
                      data-test="log-on"
                      title="Log On"
                      variant="outlined"
                      color="primary"
                      className="btn  btn-primary w-100 login-btn"
                      onClick={() => handleSubmit()}
                    >
                      LOG ON
                    </Button>
                  </div>
                  <div className="w-100 mt-3">
                    <Button
                      data-test="cancel-click"
                      title="Cancel"
                      id='onCancel'
                      variant="outlined"
                      color="primary"
                      className="btn  btn-primary w-100 login-btn"
                      onClick={() => props.setLoginType('')}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div></div>
                </div>
              </div>
             </>
            )}
  </>
  );
};

export default LoginPage;
